import * as React from 'react';
import Svg, {Circle, Ellipse, G, Path, SvgProps} from 'react-native-svg';

export default function FrontImage(props: SvgProps) {
    return (
        <Svg
            viewBox="0 0 790 470"
            fillRule="evenodd"
            clipRule="evenodd"
            {...props}
        >
            <G transform="translate(-160.26 -193.35)">
                <Path
                    d="M835.1 662.16c12.18-18 17.26-32.31 17.26-37.95 0-55.8-188.67-115.37-378-126.31-.03-28.07-135.45-214.9-214.85-214.9-56.48 0-95.22 36-95.22 119 0 105.08 41.44 193.93 100.51 260.16h570.3z"
                    fill="#039be5"
                    fillRule="nonzero"
                />
                <Path
                    d="M832 455.09c7.68 0 11.74-73.59 11.74-81.71 0-8.12-17.56-85-70.79-85-20.07 0-34.33 20.94-34.33 47.12 0 36.21 49.37 63.11 57 71.26 13.15 14.01 20.13 48.33 36.38 48.33z"
                    fill="#fd8369"
                    fillRule="nonzero"
                />
                <Path
                    d="M841.65 491.53c-4.76 0-17.66-35.7-17.66-65.2 0-34.38 31.35-76.45 35.55-91.63 7.43-26.92 19.67-79.46 57-79.46 21.2 0 33.74 23.28 33.74 47.46 0 23.28-22.7 43.43-43.12 66.57-20 22.68-17.69 41.8-28.82 69.55-11.4 28.35-30.03 52.71-36.69 52.71z"
                    fill="#ffd98e"
                    fillRule="nonzero"
                />
                <Path
                    d="M841.19 483.88c-6.91-40.79 36.84-162.83 70.07-197"
                    fillRule="nonzero"
                    stroke="#fd8369"
                />
                <Path
                    d="M783.75 317.51c6.69 5.52 45.67 70.49 48.58 101.8"
                    fillRule="nonzero"
                    stroke="#ffd98e"
                />
                <Path
                    d="M844.14 476.31h53.46l-26.73 185.85h-54.19l-26.72-185.85h53.45"
                    fill="#c9c9c9"
                    fillRule="nonzero"
                />
                <Path
                    d="M393.45 243.63c0 84.88 26 84.47 26 96.8 0 24.23-12.11 21.4-12.11 21.4s-124.73-12.16-13.89-118.2z"
                    fill="#462700"
                    fillRule="nonzero"
                />
                <Path
                    d="M848.25 575.15c-6.51-11.34-242-83.89-269.14-85-51-8.64-84.57 19.21-96.93 43.5 47.49 10.67 104 26.28 118.39 32.21h3.52c2-.11 4.27-.13 6.88-.06 17.91-.21 32.85-.63 40.91-1.11 24.46 9.12 183.78 44.37 187.7 42.24 3.92-2.13 11.56-26.76 8.67-31.78z"
                    fill="#5fc5d1"
                    fillRule="nonzero"
                />
                <Path
                    d="M421.28 523c14.46.68 43.53 6.48 74.3 13.71 18.78-18.27 32.93-43.2 31.57-73-29-66.84-145-153.25-193.5-153.21-7.29 6-8.89 49.73-14.42 75.2-2 9.34 7.57 36.34 22 66.93 33.06 25.06 64.77 53.81 80.05 70.37z"
                    fill="#ffd98e"
                    fillRule="nonzero"
                />
                <Path
                    d="M844.95 582.15l20.67 5.31-6.36 20.81-20.49-3.69 6.18-22.43zM365.08 297.74c-.38 4.84-.13 25.08 2.29 34.88-6.87-.12-47.87-37.81-2.29-34.88z"
                    fill="#e49d75"
                    fillRule="nonzero"
                />
                <Ellipse cx={355.48} cy={255.29} rx={36.25} ry={47.5} fill="#e49d75"/>
                <Path
                    d="M353.23 194.79c-47.26 0-66 68.41-66 106 0 33.69 12.65 42.71 18.42 45.47 5.77 2.76 20.33 3.27 29.62 5.52 11.9 2.89 18.36 13.81 29.36 13.81 8.54 0 13.57-8.29 13.57-15 0-27.19-45.49-50-45.49-90.46 8.72-3 41.36-26.77 41.36-36.23 0 6.42 16.7 23.86 19.52 25.1 1.24-4.09-5.59-54.21-40.36-54.21z"
                    fill="#623c00"
                    fillRule="nonzero"
                />
                <Path
                    fill="#e0e0e0"
                    d="M482.173 375.732l52.693 3.029-5.408 94.074-52.693-3.029z"
                />
                <Path
                    d="M577.79 441.98l-36.07 27.85 5.41-94.08 36.07-27.85-5.41 94.08z"
                    fill="#c9c9c9"
                    fillRule="nonzero"
                />
                <Path
                    d="M485.43 369.78l52.85 8.16M566.45 346.22l-25.69 31.72M573.33 349l-30.48 28.08"
                    fill="none"
                    stroke="#d3d3d3"
                />
                <Path
                    d="M737.35 577.71l10.16 14.93 14.44-8.9-8.57-14.44-16.03 8.41z"
                    fill="#e49d75"
                    fillRule="nonzero"
                />
                <Circle cx={329.71} cy={264.73} r={9.44} fill="#e49d75"/>
                <Path
                    d="M541.72 469.83l-12.28 3.03 5.41-94.07 12.28-3.04-5.41 94.08z"
                    fill="#d3d3d3"
                    fillRule="nonzero"
                />
                <Path
                    d="M744.65 591.84l7.46 20.63 62.53-15.87-2.38-12.07-50.78-3.62-16.83 10.93zM858.11 607.13l19.81 9.44 33.34-55.24-10.17-6.91-38.68 33.12-4.3 19.59z"
                    fill="#ff9c9c"
                    fillRule="nonzero"
                />
                <Path
                    d="M641.82 365.59c-48.36 11-131.93 100-173.22 165.08 29.93 6.32 66 15.43 93.35 23.1 41.61-32.38 72-84 76.5-90.16 11.36 23.13 92.08 117.3 98 117.3 5.92 0 15.55-4.2 19.34-11.35-3.4-20.18-88.33-207.63-113.97-203.97z"
                    fill="#6bd5e1"
                    fillRule="nonzero"
                />
                <Path
                    d="M369.93 475.52c37.47-3.41 110.19-16.77 116.41-18.09 7.82-1.65 14.92-26.06 8.05-40.51-2.37-4.26-5-4.37-7-3.9-2 .47-6.77 18.06-11.64 19-4.87.94-94.89 6.32-102.5 6.66-1-4.74-10.15-39.08-12.35-41.79-2.2-2.71-49.9 21.28-49.9 21.28a164.55 164.55 0 007.68 18.16c17.23 11.81 35.04 25.67 51.25 39.19z"
                    fill="#e49d75"
                    fillRule="nonzero"
                />
                <Path
                    d="M304.71 326c15.64-5.54 50.83 14.15 61.31 69.95-6 8.39-39 26.73-58.17 26.46-8.38-7.41-36.41-84.67-3.14-96.41z"
                    fill="#ffe4ad"
                    fillRule="nonzero"
                />
            </G>
        </Svg>
    );
}
