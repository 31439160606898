import {StatusBar} from 'expo-status-bar';
import React from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import useCachedResources from './hooks/useCachedResources';
import {ColorSchemeProvider, useColorScheme} from './providers/ColorSchemeProvider';
import Navigation from './navigation';
import {useFonts} from 'expo-font';
import AppLoading from 'expo-app-loading';
import * as WebBrowser from 'expo-web-browser';
import {Auth0Provider, useAuth0} from './providers/AuthentiationProvider';
import Constants from 'expo-constants';
import {GekkoProvider} from './providers/GekkoApiProvider';
import {configureFonts, DarkTheme, DefaultTheme, Provider as PaperProvider} from 'react-native-paper';
import {Theme} from 'react-native-paper/lib/typescript/types';
import * as Notifications from 'expo-notifications';
import {GekkoStateProvider} from './providers/GekkoStateProvider';

WebBrowser.maybeCompleteAuthSession();

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
    }),
});


const fontConfig = {
    regular: {
        fontFamily: 'jost',
        fontWeight: 'normal' as const,
    },
    medium: {
        fontFamily: 'jost-bold',
        fontWeight: 'normal' as const,
    },
    light: {
        fontFamily: 'jost',
        fontWeight: 'normal' as const,
    },
    thin: {
        fontFamily: 'jost',
        fontWeight: 'normal' as const,
    }
};

const theme: Theme = {
    ...DefaultTheme,
    roundness: 10,
    colors: {
        ...DefaultTheme.colors,
        primary: '#039be5',
        accent: '#f57c00',
        backdrop: 'rgba(0, 0, 0, 0.6)'
    },
    fonts: configureFonts({
        web: fontConfig,
        ios: fontConfig,
        android: fontConfig
    })
};

const darkTheme: Theme = {
    ...DarkTheme,
    roundness: 10,
    colors: {
        ...DarkTheme.colors,
        primary: '#039be5',
        accent: '#f57c00',
        backdrop: 'rgba(0, 0, 0, 0.6)'
    },
    fonts: configureFonts({
        web: fontConfig,
        ios: fontConfig,
        android: fontConfig
    })
};

export function Main() {
    const isLoadingComplete = useCachedResources();
    const {initialized} = useAuth0();
    const [fontsLoaded] = useFonts({
        'jost-bold': require('./assets/fonts/jost-v1-latin-700.ttf'),
        'jost': require('./assets/fonts/jost-v1-latin-regular.ttf')
    });
    const {colorScheme} = useColorScheme();
    if (!isLoadingComplete || !fontsLoaded || !initialized) {
        return <AppLoading/>;
    } else {
        return (
            <PaperProvider theme={colorScheme === 'dark' ? darkTheme : theme}>
                <SafeAreaProvider>
                    <Navigation/>
                    <StatusBar/>
                </SafeAreaProvider>
            </PaperProvider>
        );
    }
}

export default function App() {
    return (
        <Auth0Provider
            clientId={Constants.manifest.extra.auth0.clientId}
            audience={Constants.manifest.extra.auth0.audience}
            domain={Constants.manifest.extra.auth0.domain}
        >
            <GekkoProvider apiUrl={Constants.manifest.extra.gekko.apiUrl}>
                <GekkoStateProvider>
                    <ColorSchemeProvider>
                        <Main/>
                    </ColorSchemeProvider>
                </GekkoStateProvider>
            </GekkoProvider>
        </Auth0Provider>
    );
}
