import * as AuthSession from 'expo-auth-session';
import React, {useContext, useEffect, useState} from 'react';
import {Alert, Platform} from 'react-native';
import {getItem, setItem} from '../helpers/Storage';
import jwtDecode from 'jwt-decode';

export interface User {
    // Common auth0 user fields
    name: string;
    nickname: string;
    picture: string;
    sub: string;
    updated_at: string;

    // Google user fields
    email?: string;
    email_verified?: boolean;
    given_name?: string;
    family_name?: string;
    locale?: string;

    // Artificial fields
    last_update?: number;
}

export interface DecodedAccessToken {
    aud: string[];
    azp: string;
    exp: number;
    iat: number;
    sub: string;
    permissions: string[];
}

interface Auth0Context {
    request?: AuthSession.AuthRequest | null;
    result?: AuthSession.AuthSessionResult | null;

    /**
     * ```ts
     * await login();
     * ```
     *
     * Prompt the user to authenticate in a user interaction or web browsers will
     * block it.
     */
    login(): Promise<AuthSession.AuthSessionResult>;

    /**
     * Function to trigger logout
     */
    logout?(): Promise<void>;

    /**
     * Trigger a token refresh. Useful when permissions changed.
     */
    refreshToken(): Promise<void>;

    user?: User | null;
    /**
     * The Auth0 access token.
     */
    accessToken?: string;
    /**
     * Decoded access token, used to retrieve permissions
     */
    decodedAccessToken?: DecodedAccessToken;
    /**
     * Whether the authentication module is initialized
     */
    initialized: boolean;

    /**
     * Function to do authenticated API calls
     */
    fetchAuthorized(url: string, requestInfo: RequestInit): Promise<Response>;

    /**
     * Login state. Can be 'firstLogin', 'sessionRestored', 'loggedOut' or undefined if there has not been any interaction
     */
    loginState: 'firstLogin' | 'sessionRestored' | 'loggedOut' | undefined
}

interface Auth0ProviderOptions {
    /**
     * The child nodes your provider has wrapped.
     */
    children: React.ReactElement;
    /**
     * The client ID found on your application settings page.
     */
    clientId: string;
    /**
     * The default audience to be used for requesting API access.
     */
    audience: string;
    /**
     * Your Auth0 account domain such as `'example.auth0.com'`,
     * `'example.eu.auth0.com'` or , `'example.mycompany.com'`
     * (when using [custom domains](https://auth0.com/docs/custom-domains))
     */
    domain: string;
}

const useProxy = Platform.select({web: false, default: true});
const redirectUri = AuthSession.makeRedirectUri({useProxy});

export const Auth0Context = React.createContext<Auth0Context>({
    request: undefined,
    result: undefined,
    login: () => Promise.reject(),
    logout: undefined,
    user: undefined,
    accessToken: undefined,
    decodedAccessToken: undefined,
    initialized: false,
    loginState: undefined,
    refreshToken: () => Promise.reject(),
    fetchAuthorized: () => Promise.reject()
});
/**
 * ```ts
 * const {
 *   // Provider initialization:
 *   initialization,
 *   // Auth state:
 *   request,
 *   result,
 *   user,
 *   accessToken,
 *   // Auth methods:
 *   login,
 *   logout
 * } = useAuth0();
 * ```
 *
 * Use the `useAuth0` hook in your components to access the auth state and methods.
 */
export const useAuth0 = (): Auth0Context => useContext(Auth0Context);

interface RawToken {
    access_token: string;
    expires_in: number;
    // Only sends back a refresh token if rotation is enabled
    // https://auth0.com/docs/tokens/refresh-tokens/refresh-token-rotation
    refresh_token: string;
    token_type: string;
}

interface Token {
    access_token: string;
    decoded_token: DecodedAccessToken;
    expires_in: number;
    // Only sends back a refresh token if rotation is enabled
    // https://auth0.com/docs/tokens/refresh-tokens/refresh-token-rotation
    refresh_token: string;
    token_type: string;
    expire_date: number;
    code_verifier: string;
    client_id: string;
    grant_type: 'refresh_token';
    redirect_uri: string;
}

interface TokenData {
    grant_type: 'authorization_code';
    client_id: string;
    code: string;
    redirect_uri: string;
    code_verifier: string;
}

interface RefreshTokenData {
    grant_type: 'refresh_token';
    client_id: string;
    refresh_token: string;
    redirect_uri: string;
    code_verifier: string;
}

async function getUserData(
    token: Token,
    domain: string,
    setUser: (user: User) => void
): Promise<void> {
    const userInfoResponse = await fetch(`https://${domain}/userinfo`, {
        headers: {Authorization: `Bearer ${token.access_token}`},
    });
    const userInfo = await userInfoResponse.json();
    setUser({
        ...userInfo,
        last_update: Date.now()
    });
}

async function fetchAccessToken(
    data: TokenData | RefreshTokenData,
    domain: string,
    setTokenData: (token: Token) => void,
    setUser: (user: User) => void,
    logout: () => Promise<void>
): Promise<Token | void> {
    // URLSearchParams doesn't work in RN.
    // `new URLSearchParams(Object.entries({ a: "b", c: "d" })).toString()` gives "0=a,b&1=c,d"
    // So manually do the encoding
    const formBody = Object.entries(data)
        .map(
            ([key, value]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
    // Fetch the access token and possibly the refresh token (if rotation is
    // enabled) following
    // https://auth0.com/docs/tokens/refresh-tokens/get-refresh-tokens
    const tokenResponse = await fetch(`https://${domain}/oauth/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: formBody,
    });
    if (tokenResponse.ok && tokenResponse.status < 300) {
        const rawToken = (await tokenResponse.json()) as RawToken;
        const token: Token = {
            ...data,
            ...rawToken,
            decoded_token: jwtDecode<DecodedAccessToken>(rawToken.access_token),
            expire_date: Date.now() + (rawToken.expires_in * 1000) - 600,
            grant_type: 'refresh_token'
        };

        // Set state at the same time to trigger a single update on the context
        // otherwise components are sent two separate updates
        await getUserData(token, domain, setUser);
        setTokenData(token);
        return token;
    } else {
        await logout();
    }
}

function authorizeFetchJson(accessToken: string | undefined, url: string, requestInfo: RequestInit): Promise<Response> {
    return fetch(
        url,
        {
            ...requestInfo,
            headers: {
                ...requestInfo.headers,
                Authorization: `Bearer ${accessToken}`
            }
        }
    );
}

async function fetchAuthorized(
    tokenData: Token | null | undefined,
    domain: string,
    setTokenData: (token: Token) => void,
    setUser: (user: User) => void,
    logout: () => Promise<void>,
    url: string,
    requestInfo: RequestInit
): Promise<Response> {
    let activeToken: Token | null | void = tokenData;
    if (tokenData && tokenData.expire_date < Date.now()) {
        activeToken = await fetchAccessToken(
            tokenData,
            domain,
            setTokenData,
            setUser,
            logout
        );
    }
    return authorizeFetchJson((activeToken as Token)?.access_token, url, requestInfo);
}

async function logout(
    tokenData: Token | null | undefined,
    domain: string,
    setTokenData: (token: Token | null) => void,
    setUser: (user: User | null) => void,
    setAuthResult: (authResult: null) => void,
    setLoginState: (loginState: Auth0Context['loginState']) => void,
    setInitialized: (initialized: boolean) => void
): Promise<void> {
    setLoginState('loggedOut');
    if (tokenData) {
        await AuthSession.revokeAsync(
            {token: tokenData.refresh_token, clientId: tokenData.client_id},
            {revocationEndpoint: `https://${domain}/oauth/revoke`});
    }
    setAuthResult(null);
    setTokenData(null);
    setUser(null);
    setInitialized(true);
}

/**
 * ```jsx
 * <Auth0Provider
 *   domain={domain}
 *   clientId={clientId}
 *   audience={audience}>
 *   <MyApp />
 * </Auth0Provider>
 * ```
 *
 * Provides the Auth0Context to its child components. It's recommended offline
 * access is enabled for the mobile client on the Auth0 dashboard to keep the
 * user signed in after token expiration.
 *
 * For native applications, refresh tokens (offline access) improve the
 * authentication experience significantly. The user has to authenticate only
 * once, through the web authentication process. Subsequent re-authentication
 * can take place without user interaction, using the refresh token.
 */
export function Auth0Provider({
    children,
    clientId,
    audience,
    domain
}: Auth0ProviderOptions) {
    const [tokenData, setTokenData] = useState<Token | null>();
    const [user, setUser] = useState<User | null>();
    const [initialized, setInitialized] = useState<boolean>(false);
    const [loginState, setLoginState] = useState<Auth0Context['loginState']>(undefined);

    const authSessionParams = {
        redirectUri,
        clientId,
        responseType: AuthSession.ResponseType.Code,
        scopes: ['offline_access', 'openid', 'profile', 'email'],
        extraParams: {
            audience
        },
    };
    const authorizationEndpoint = `https://${domain}/authorize`;
    const [authSessionRequest, authSessionResult, promptAsync] = AuthSession.useAuthRequest(
        {
            ...authSessionParams,
            // Server should prompt the user to re-authenticate.
            prompt: AuthSession.Prompt.Login,
        },
        {
            authorizationEndpoint,
        } as AuthSession.DiscoveryDocument
    );

    const [authResult, setAuthResult] = useState<typeof authSessionResult>(authSessionResult);
    const logoutFunction = () => logout(tokenData, domain, setTokenData, setUser, setAuthResult, setLoginState, setInitialized);

    useEffect(() => {
        if (initialized) {
            setItem('user', user);
        }
    }, [user, initialized]);

    useEffect(() => {
        setAuthResult(authSessionResult);
    }, [authSessionResult]);

    useEffect(() => {
        if (initialized) {
            const {access_token, refresh_token, decoded_token, ...baseTokenData} = tokenData || {};
            setItem('tokenData', Object.entries(baseTokenData).length ? baseTokenData : undefined);
            setItem('accessToken', access_token || null);
            setItem('refreshToken', refresh_token || null);
        }
    }, [tokenData, initialized]);

    useEffect(() => {
        async function getStoredInformation() {
            const baseTokenData = await getItem<Token | null>('tokenData');
            const accessToken = await getItem<string | null>('accessToken');
            const refreshToken = await getItem<string | null>('refreshToken');
            const user = await getItem<User>('user');
            if (user) {
                setUser(user);
            }
            if (baseTokenData && accessToken && refreshToken) {
                setTokenData({
                    ...baseTokenData,
                    decoded_token: jwtDecode(accessToken),
                    access_token: accessToken,
                    refresh_token: refreshToken
                });
            } else {
                setInitialized(true);
            }
        }

        getStoredInformation();
    }, []);

    useEffect(() => {
        async function getToken() {
            if (!authResult && !tokenData) {
                // User is not logged in
                return;
            }
            if (tokenData) {
                // User is logged in
                if (tokenData.expire_date < Date.now()) {
                    await fetchAccessToken(
                        {...tokenData},
                        domain,
                        setTokenData,
                        setUser,
                        logoutFunction
                    );
                }
                if (!user) {
                    await getUserData(tokenData, domain, setUser);
                } else if (Date.now() - (user?.last_update || 0) > 3600) {
                    await getUserData(tokenData, domain, setUser); // Refresh data async to keep loading time short
                }
                if (!initialized) {
                    setInitialized(true);
                    setLoginState('sessionRestored');
                }
            } else if (
                authResult?.type === 'success' &&
                authSessionRequest?.redirectUri &&
                authSessionRequest?.codeVerifier
            ) {
                // User just logged in, but no access token is retrieved yet
                await fetchAccessToken(
                    {
                        grant_type: 'authorization_code',
                        client_id: clientId,
                        code: authResult.params.code,
                        redirect_uri: authSessionRequest.redirectUri,
                        code_verifier: authSessionRequest.codeVerifier
                    },
                    domain,
                    setTokenData,
                    setUser,
                    logoutFunction
                );
                if (!initialized) {
                    setInitialized(true);
                }
                setLoginState('firstLogin');
            } else {
                Alert.alert(
                    'Login fehlgeschlagen',
                    authResult?.type === 'error'
                        ? authResult.params.error_description
                        : 'Ein unbekannter Fehler ist aufgetreten'
                );
                if (!initialized) {
                    setInitialized(true);
                }
            }
        }

        getToken();
    }, [authResult, clientId, tokenData]);
    return (
        <Auth0Context.Provider
            value={{
                request: authSessionRequest,
                result: authResult,
                login: () => promptAsync?.({useProxy}),
                user,
                accessToken: tokenData?.access_token,
                decodedAccessToken: tokenData?.decoded_token,
                initialized,
                loginState,
                fetchAuthorized: (url: string, requestInfo: RequestInit) => fetchAuthorized(
                    tokenData,
                    domain,
                    setTokenData,
                    setUser,
                    logoutFunction,
                    url,
                    requestInfo
                ),
                logout: logoutFunction,
                refreshToken: async () => {
                    await fetchAccessToken(
                        tokenData as RefreshTokenData,
                        domain,
                        setTokenData,
                        setUser,
                        logoutFunction
                    );
                }
            }}
        >
            {children}
        </Auth0Context.Provider>
    );
}
