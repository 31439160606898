import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';
import {useGekko} from '../providers/GekkoApiProvider';
import {useAuth0} from '../providers/AuthentiationProvider';
import PermissionCard from '../components/PermissionCard';
import UserOverviewCard from '../components/UserOverviewCard';
import {StackNavigationProp} from '@react-navigation/stack';
import {AccessTabParamList} from '../types';
import RestrictedScrollView from '../components/RestrictedScrollView';

export default function AccessScreen({navigation}: { navigation: StackNavigationProp<AccessTabParamList, 'overview'> }) {
    const {fillUsers, fillPermissionDescriptions} = useGekko();
    const {decodedAccessToken} = useAuth0();
    const userIsPermitted = decodedAccessToken?.permissions?.includes('update:role');

    useFocusEffect(
        React.useCallback(() => {
            fillPermissionDescriptions();
            if (userIsPermitted) {
                fillUsers();
            }
        }, [userIsPermitted])
    );

    return (
        <RestrictedScrollView>
            <PermissionCard/>
            {userIsPermitted && <UserOverviewCard navigation={navigation}/>}
        </RestrictedScrollView>
    );
}
