import * as React from 'react';
import {useEffect, useState} from 'react';
import {Checkbox} from 'react-native-paper';
import {GekkoContext, useGekko} from '../providers/GekkoApiProvider';
import {useAuth0} from '../providers/AuthentiationProvider';

async function toggleNotificationHook(
    disabled: boolean,
    setDisabled: (state: boolean) => void,
    triggerAction: GekkoContext['triggerAction'],
    setNotificationHooks: (hooks: Record<string, string[]>) => void,
    eventId: string,
    checkedPreviously: boolean,
    userId: string | undefined,
    subjectUserId: string
): Promise<void> {
    if (userId && !disabled) {
        setDisabled(true);
        try {
            const hooks = await triggerAction<Record<string, string[]>>(
                `users/${encodeURIComponent(userId)}/notificationHooks`,
                checkedPreviously ? 'DELETE' : 'POST',
                {userId: subjectUserId, event: eventId}
            );
            if (hooks) {
                setNotificationHooks(hooks);
            }
        } catch (e) {
            setDisabled(false);
        }
    }
}

export default function NotificationHookItem(
    {eventId, label, subjectUserId}: { eventId: string, label: string, subjectUserId: string }
) {
    const [checked, setChecked] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const {triggerAction, notificationHooks, setNotificationHooks} = useGekko();
    const {decodedAccessToken} = useAuth0();

    useEffect(() => {
        setDisabled(false);
        setChecked(notificationHooks?.[subjectUserId]?.includes(eventId));
    }, [notificationHooks]);
    return (
        <Checkbox.Item
            label={label}
            status={checked ? 'checked' : 'unchecked'}
            disabled={disabled}
            onPress={() => toggleNotificationHook(disabled, setDisabled, triggerAction, setNotificationHooks, eventId, checked, decodedAccessToken?.sub, subjectUserId)}
        />
    );
}
