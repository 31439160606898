import * as React from 'react';
import {Button} from 'react-native-paper';
import {StyleSheet} from 'react-native';

export default function StandardButton({style, ...props}: React.ComponentProps<typeof Button>) {
    return (
        <Button
            uppercase={false}
            style={[styles.button, style]}
            {...props}
        />
    );
}

const styles = StyleSheet.create({
    button: {
        elevation: 0
    }
});
