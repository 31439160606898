import * as React from 'react';
import {Card, Divider, Text, Title} from 'react-native-paper';
import StandardCard from './StandardCard';
import {StyleSheet} from 'react-native';
import CardListContent from './CardListContent';
import {useGekkoState} from '../providers/GekkoStateProvider';
import {useGekko} from '../providers/GekkoApiProvider';
import {StackNavigationProp} from '@react-navigation/stack';
import {HomeTabParamList} from '../types';
import StandardButton from './StandardButton';
import BlindGroupSummaryItem from './BlindGroupSummaryItem';

export default function BlindCard({navigation}: { navigation: StackNavigationProp<HomeTabParamList, 'overview'> }) {
    const {blindGroups} = useGekko();
    const {blinds} = useGekkoState();
    const blindList = Object.values(blinds || {});

    return (
        <>
            <Title>Rollläden</Title>
            <StandardCard>
                <CardListContent>
                    <BlindGroupSummaryItem
                        boldTitle={false}
                        blindGroup={{name: 'Alle Rolläden', blinds: Object.keys(blinds || {})}}
                    />
                    <Divider style={styles.divider}/>
                    {blindGroups.map((blindGroup) => (
                        <BlindGroupSummaryItem
                            key={blindGroup.name}
                            boldTitle={false}
                            blindGroup={blindGroup}
                        />
                    ))}
                    {!blindList.length && <Text style={styles.text}>Keine Gruppen vorhanden</Text>}
                </CardListContent>
                <Card.Actions>
                    <StandardButton onPress={() => navigation.navigate('blinds')}>
                        Alle Rollläden anzeigen
                    </StandardButton>
                </Card.Actions>
            </StandardCard>
        </>
    );
}

const styles = StyleSheet.create({
    text: {
        margin: 16
    },
    divider: {
        marginHorizontal: 16
    }
});
