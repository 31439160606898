import * as React from 'react';
import RestrictedScrollView from '../components/RestrictedScrollView';
import TemperatureCard from '../components/TemperatureCard';

export default function TempsScreen() {
    return (
        <RestrictedScrollView>
            <TemperatureCard overviewMode={false}/>
        </RestrictedScrollView>
    );
}
