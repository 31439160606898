import React, {useContext, useEffect, useState} from 'react';
import {ColorSchemeName, useColorScheme as _useColorScheme} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

export interface ColorSchemeContext {
    colorScheme: NonNullable<ColorSchemeName>,
    colorSchemeNoFallback: ColorSchemeName,
    setColorScheme: (colorScheme: ColorSchemeName) => void
}

export const ColorSchemeContext = React.createContext<ColorSchemeContext>({
    colorScheme: 'light',
    colorSchemeNoFallback: null,
    setColorScheme: () => undefined
});

/**
 * ```ts
 * const {
 *   // Current color scheme, including system defaults and fallback:
 *   colorScheme,
 *   // Color scheme setting by user:
 *   colorSchemeNoFallback,
 *   // Set a new color scheme:
 *   setColorScheme
 * } = useColorScheme();
 * ```
 *
 * Use the `useColorScheme` hook in your components to access the Gekko api
 */
export const useColorScheme = (): ColorSchemeContext => useContext(ColorSchemeContext);

/**
 * ```jsx
 * <ColorSchemeProvider>
 *   <MyApp />
 * </ColorSchemeProvider>
 * ```
 *
 * Provides the ColorSchemeContext to its child components. Using the `useColorScheme` hook,
 * you can access the state and change the scheme globally.
 */
export function ColorSchemeProvider({children}: { children: React.ReactNode }) {
    const [colorScheme, setColorScheme] = useState<ColorSchemeName>(null);
    const [initialValueRetrieved, setInitialValueRetrieved] = useState<boolean>(false);
    const systemColorScheme = _useColorScheme();

    useEffect(() => {
        async function getInitialValue() {
            setColorScheme(await AsyncStorage.getItem('colorScheme') as ColorSchemeName);
            setInitialValueRetrieved(true);
        }

        getInitialValue();
    }, []);

    useEffect(() => {
        async function setValue() {
            if (initialValueRetrieved) {
                if (colorScheme) {
                    await AsyncStorage.setItem('colorScheme', colorScheme);
                } else {
                    await AsyncStorage.removeItem('colorScheme');
                }
            }
        }

        setValue();
    }, [colorScheme]);
    return (
        <ColorSchemeContext.Provider
            value={{
                colorScheme: colorScheme || systemColorScheme || 'light' as NonNullable<ColorSchemeName>,
                colorSchemeNoFallback: colorScheme,
                setColorScheme
            }}
        >
            {children}
        </ColorSchemeContext.Provider>
    );
}
