import * as React from 'react';
import {useState} from 'react';
import {IconButton, List, Menu, useTheme} from 'react-native-paper';
import {GekkoStateContext} from '../providers/GekkoStateProvider';
import {GekkoContext, useGekko} from '../providers/GekkoApiProvider';
import {StyleSheet} from 'react-native';

async function toggleBlind(
    triggerAction: GekkoContext['triggerAction'],
    blindIdentifier: string | undefined,
    toggle: -2 | 0 | 2
): Promise<void> {
    await triggerAction(
        `blinds/${blindIdentifier || ''}`,
        'POST',
        {currentValue: {toggle}}
    );
}

async function setPercentage(
    triggerAction: GekkoContext['triggerAction'],
    setMenuOpen: (open: boolean) => void,
    blindIdentifier: string | undefined,
    percentage: number
): Promise<void> {
    setMenuOpen(false);
    await triggerAction(
        `blinds/${blindIdentifier || ''}`,
        'POST',
        {currentValue: {height: percentage}}
    );
}

function getSwitchInfo(switchValue: -2 | 0 | 2): string {
    switch (switchValue) {
    case -2: {
        return ' • Fährt herunter';
    }
    case 2: {
        return ' • Fährt hoch';
    }
    default: {
        return '';
    }
    }
}

function getHeightString(height: number | undefined): string {
    let stateName: string;
    if (typeof height !== 'number') {
        stateName = 'Unbekannt';
    } else if (height < 33) {
        stateName = 'Offen';
    } else if (height < 67) {
        stateName = 'Halboffen';
    } else {
        stateName = 'Geschlossen';
    }
    return `${stateName} • ${typeof height === 'number' ? height : '-'}%`;
}

function getButtonValue(
    blind: Exclude<GekkoStateContext['blinds'], undefined>[string],
    switchValue: -2 | 2
): -2 | 0 | 2 {
    return blind.currentValue?.toggle === switchValue ? 0 : switchValue;
}

export default function BlindItem({blind}: { blind: Exclude<GekkoStateContext['blinds'], undefined>[string] }) {
    const {triggerAction} = useGekko();
    const {colors} = useTheme();
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <List.Item
            key={blind.identifier}
            title={blind.name}
            description={`${getHeightString(blind.currentValue?.height)}${getSwitchInfo(blind.currentValue?.toggle)}`}
            right={() => (
                <>
                    <IconButton
                        icon="arrow-up"
                        style={styles.iconButton}
                        color={blind.currentValue?.toggle === 2 ? colors.accent : colors.text}
                        onPress={() => toggleBlind(triggerAction, blind.identifier, getButtonValue(blind, 2))}
                    />
                    <IconButton
                        icon="arrow-down"
                        style={styles.iconButton}
                        color={blind.currentValue?.toggle === -2 ? colors.accent : colors.text}
                        onPress={() => toggleBlind(triggerAction, blind.identifier, getButtonValue(blind, -2))}
                    />
                    <Menu
                        visible={menuOpen}
                        onDismiss={() => setMenuOpen(false)}
                        anchor={
                            <IconButton
                                icon="percent-outline"
                                style={styles.iconButton}
                                color={colors.text}
                                onPress={() => setMenuOpen(true)}
                            />
                        }
                    >
                        <Menu.Item onPress={() => setPercentage(triggerAction, setMenuOpen, blind.identifier, 25)} title="25%"/>
                        <Menu.Item onPress={() => setPercentage(triggerAction, setMenuOpen, blind.identifier, 50)} title="50%"/>
                        <Menu.Item onPress={() => setPercentage(triggerAction, setMenuOpen, blind.identifier, 70)} title="75%"/>
                    </Menu>
                </>
            )}
        />
    );
}

const styles = StyleSheet.create({
    iconButton: {
        marginHorizontal: 0
    }
});
