import * as React from 'react';
import {useEffect, useState} from 'react';
import {Menu} from 'react-native-paper';
import {GekkoContext, useGekko} from '../providers/GekkoApiProvider';
import {StyleSheet} from 'react-native';
import {useColorScheme} from '../providers/ColorSchemeProvider';
import {Auth0User} from '../../backend/routes/util/user-data-cache';

async function triggerAddPermission(
    disabled: boolean,
    setDisabled: (state: boolean) => void,
    triggerAction: GekkoContext['triggerAction'],
    fillUsers: () => Promise<void>,
    onComplete: (() => void) | undefined,
    userId: string | undefined,
    permission: string
): Promise<void> {
    if (userId && !disabled) {
        setDisabled(true);
        try {
            await triggerAction(
                `users/${encodeURIComponent(userId)}/permissions`,
                'POST',
                {permission}
            );
            await fillUsers();
            onComplete?.();
        } catch (e) {
            setDisabled(false);
        }
    }
}

export default function UserAddPermissionEntry(
    {permission, user, onComplete}: { permission: string, user: Auth0User, onComplete?: () => void }
) {
    const [disabled, setDisabled] = useState<boolean>(false);
    const {triggerAction, fillUsers, permissionDescriptions} = useGekko();
    const {colorScheme} = useColorScheme();
    const loadingStyle = colorScheme === 'dark' ? styles.itemCardLoadingDark : styles.itemCardLoading;

    useEffect(() => {
        setDisabled(false);
    }, [user]);
    return (
        <Menu.Item
            title={permissionDescriptions?.[permission] || ''}
            titleStyle={permissionDescriptions ? {} : loadingStyle}
            disabled={disabled}
            onPress={() => triggerAddPermission(disabled, setDisabled, triggerAction, fillUsers, onComplete, user.user_id, permission)}
        />
    );
}

const styles = StyleSheet.create({
    itemCardLoadingDark: {
        backgroundColor: 'rgba(255, 255, 255, .05)',
        minHeight: 20
    },
    itemCardLoading: {
        backgroundColor: 'rgba(0, 0, 0, .05)',
        minHeight: 20
    }
});
