import * as React from 'react';
import RestrictedScrollView from '../components/RestrictedScrollView';
import {useGekko} from '../providers/GekkoApiProvider';
import {useGekkoState} from '../providers/GekkoStateProvider';
import StandardCard from '../components/StandardCard';
import CardListContent from '../components/CardListContent';
import BlindGroupSummaryItem from '../components/BlindGroupSummaryItem';
import AddBlindGroupDialog from '../components/AddBlindGroupDialog';
import BlindGroup from '../components/BlindGroup';

export default function BlindsScreen() {
    const {blindGroups} = useGekko();
    const {blinds} = useGekkoState();
    const ungroupedBlinds = Object.keys(blinds || {})
        .filter((blind) =>
            blindGroups.every((group) =>
                !group.blinds.includes(blind)
            )
        );

    return (
        <RestrictedScrollView>
            <StandardCard>
                <CardListContent>
                    <BlindGroupSummaryItem
                        boldTitle={true}
                        blindGroup={{name: 'Alle Rolläden', blinds: Object.keys(blinds || {})}}
                    />
                </CardListContent>
            </StandardCard>
            {blindGroups.map((blindGroup) =>
                <BlindGroup key={blindGroup.name} blindGroup={blindGroup} editable={true}/>)}
            <AddBlindGroupDialog/>
            {ungroupedBlinds.length > 0 && <BlindGroup
                blindGroup={{
                    name: 'Nicht zugeordnete Elemente',
                    blinds: ungroupedBlinds
                }}
                editable={false}
            />}
        </RestrictedScrollView>
    );
}
