import * as React from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import {useGekkoState} from '../providers/GekkoStateProvider';
import {GekkoContext, useGekko} from '../providers/GekkoApiProvider';
import ActionChip from './ActionChip';
import {useAuth0} from '../providers/AuthentiationProvider';

async function toggleHand(
    triggerAction: GekkoContext['triggerAction'],
    id: string, currentValue: 0 | 1,
): Promise<void> {
    await triggerAction(`hands/${id}`, 'POST', {currentValue: currentValue === 0 ? 1 : 0});
}

export default function ShortHandChips() {
    const {decodedAccessToken} = useAuth0();
    const {triggerAction} = useGekko();
    const {hands} = useGekkoState();
    const handsList = Object.values(hands || {});

    return (
        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={styles.view}>
            {decodedAccessToken?.permissions.includes('update:door') && (
                <ActionChip
                    icon="key"
                    style={styles.chip}
                    preferPrimaryBackground={true}
                    onPress={() => triggerAction('door/open', 'POST')}
                >
                    Haustüre öffnen
                </ActionChip>
            )}
            {decodedAccessToken?.permissions.includes('update:door')
            && handsList.map((hand, index) => (
                <ActionChip
                    key={hand.identifier}
                    style={index < handsList.length ? styles.chip : []}
                    onPress={() => toggleHand(triggerAction, hand.identifier, hand.currentValue)}
                >
                    {hand.name}
                </ActionChip>
            ))}
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    view: {
        marginBottom: 24
    },
    chip: {
        marginRight: 4
    }
});
