import {StackScreenProps} from '@react-navigation/stack';
import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import {RootStackParamList} from '../types';
import Logo from '../components/Logo';
import {Paragraph, Title} from 'react-native-paper';
import StandardButton from '../components/StandardButton';

export default function NotFoundScreen({
    navigation,
}: StackScreenProps<RootStackParamList, 'NotFound'>) {
    return (
        <View style={styles.container}>
            <View style={styles.view}>
                <Logo size={64} style={styles.logo}/>
                <Title>Seite nicht gefunden</Title>
                <Paragraph style={styles.paragraph}>
                    Die angeforderte Seite konnte nicht gefunden werden.
                </Paragraph>
                <StandardButton
                    mode="contained"
                    onPress={() => navigation.canGoBack() ? navigation.goBack() : navigation.replace('Root')}
                >
                    Zurück zum Startbildschirm
                </StandardButton>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 16,
        paddingHorizontal: 32
    },
    view: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
        maxWidth: 400
    },
    paragraph: {
        marginTop: 8,
        marginBottom: 24
    },
    logo: {
        marginLeft: -8
    }
});
