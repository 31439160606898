import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Button, useTheme} from 'react-native-paper';
import {ViewStyle} from 'react-native';
import {ResultState, trackPromise} from '../helpers/PromiseTracker';

export default function ActionButton({
    children,
    icon,
    onPress,
    style,
    contained = false,
    disabled = false
}: {
    children: React.ReactNode,
    icon?: string,
    onPress: () => Promise<unknown> | void,
    style?: ViewStyle,
    contained?: boolean,
    disabled?: boolean
}) {
    const [actionState, setActionState] = useState<ResultState>('untouched');
    const {colors} = useTheme();
    const actionPromise = useRef<Promise<unknown> | undefined | void>(undefined);
    const stateColorMapping: Record<ResultState, string> = {
        success: '#008800',
        error: colors.error,
        untouched: colors.primary,
        pending: colors.primary
    };

    useEffect(() => {
        return () => actionPromise.current = undefined;
    }, []);

    return (
        <Button
            onPress={() => trackPromise(onPress?.(), actionPromise, setActionState)}
            disabled={disabled}
            uppercase={false}
            style={[{elevation: 0}, style]}
            loading={actionState === 'pending'}
            color={stateColorMapping[actionState]}
            mode={(actionState !== 'pending' && contained) ? 'contained' : 'text'}
            icon={icon}
        >
            {children}
        </Button>
    );
}
