import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import {Paragraph, Title} from 'react-native-paper';
import {useAuth0} from '../providers/AuthentiationProvider';
import Logo from '../components/Logo';
import ActionButton from '../components/ActionButton';

export default function Login() {
    const {login} = useAuth0();

    return (
        <View style={styles.container}>
            <View style={styles.view}>
                <Logo size={64} style={styles.logo}/>
                <Title>Bei Schäfer Home anmelden</Title>
                <Paragraph style={styles.paragraph}>
                    Bitte nutze deinen Google- oder Facebook-Account um dich anzumelden.
                    Sobald du angemeldet bist, können dir Administratoren Rechte vergeben.
                </Paragraph>
                <ActionButton
                    onPress={login}
                    contained={true}
                >
                    Anmelden
                </ActionButton>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 16,
        paddingHorizontal: 32
    },
    view: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
        maxWidth: 400
    },
    paragraph: {
        marginTop: 8,
        marginBottom: 24
    },
    logo: {
        marginLeft: -8
    }
});
