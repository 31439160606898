import * as React from 'react';
import {Card} from 'react-native-paper';
import {StyleSheet} from 'react-native';

export default function CardListContent({style, ...props}: React.ComponentProps<typeof Card.Content>) {
    return (
        <Card.Content
            style={[styles.content, style]}
            {...props}
        />
    );
}

const styles = StyleSheet.create({
    content: {
        paddingBottom: 8,
        paddingTop: 8,
        paddingHorizontal: 0
    }
});
