import * as React from 'react';
import {useState} from 'react';
import {Card, IconButton, List, Text, Title, useTheme} from 'react-native-paper';
import StandardCard from './StandardCard';
import {StyleSheet} from 'react-native';
import CardListContent from './CardListContent';
import {GekkoStateContext, useGekkoState} from '../providers/GekkoStateProvider';
import TemperatureEditDialog from './TemperatureEditDialog';
import StandardButton from './StandardButton';
import {StackNavigationProp} from '@react-navigation/stack';
import {HomeTabParamList} from '../types';
import {useGekko} from '../providers/GekkoApiProvider';

function toggleFavorite(
    temp: Exclude<GekkoStateContext['temps'], undefined>[string],
    favoriteTemps: string[],
    setFavoriteTemps: (favoriteTemps: string[]) => void
): void {
    if (favoriteTemps.includes(temp.identifier)) {
        setFavoriteTemps(favoriteTemps.filter((fav) => fav !== temp.identifier));
    } else {
        setFavoriteTemps([...favoriteTemps, temp.identifier]);
    }
}

export default function TemperatureCard({overviewMode}: { overviewMode: false }): React.ReactElement ;
export default function TemperatureCard(
    {navigation, overviewMode}: { navigation?: StackNavigationProp<HomeTabParamList, 'overview'>, overviewMode: true }
): React.ReactElement ;
export default function TemperatureCard(
    {
        navigation,
        overviewMode
    }: { navigation?: StackNavigationProp<HomeTabParamList, 'overview'>, overviewMode: boolean }
) {
    const {favoriteTemps, setFavoriteTemps} = useGekko();
    const {temps} = useGekkoState();
    const {colors} = useTheme();
    const tempList = Object.values(temps || {}).filter((temp) => !overviewMode || favoriteTemps.includes(temp.identifier));
    const [editTemp, setEditTemp] = useState<Exclude<GekkoStateContext['temps'], undefined>[string] | undefined>(undefined);

    return (
        <>
            {overviewMode && <Title>Raumtemperaturen</Title>}
            <StandardCard>
                <CardListContent>
                    {tempList.map((temp) => {
                        const target = temp.currentValue?.target.toFixed(1) || '-';
                        const actual = temp.currentValue?.actual.toFixed(1) || '-';
                        return <List.Item
                            key={temp.identifier}
                            title={temp.name}
                            description={`Soll ${target}°C • Ist ${actual}°C`}
                            onPress={() => setEditTemp(temp)}
                            right={() => !overviewMode && (<IconButton
                                icon={favoriteTemps.includes(temp.identifier) ? 'star' : 'star-outline'}
                                color={colors.text}
                                onPress={() => toggleFavorite(temp, favoriteTemps, setFavoriteTemps)}
                            />)}
                        />;
                    })}
                    {!tempList.length && overviewMode &&
                    <Text style={styles.text}>Füge Räume zu deinen Favoriten hinzu, um sie hier zu sehen.</Text>}
                    {!tempList.length && !overviewMode &&
                    <Text style={styles.text}>Keine Räume vorhanden</Text>}
                </CardListContent>
                {overviewMode && (
                    <Card.Actions>
                        <StandardButton onPress={() => navigation?.navigate('temps')}>Alle Räume anzeigen</StandardButton>
                    </Card.Actions>
                )}
            </StandardCard>
            <TemperatureEditDialog temp={editTemp}/>
        </>
    );
}

const styles = StyleSheet.create({
    text: {
        margin: 16
    }
});
