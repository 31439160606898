import * as React from 'react';
import {Card, Title} from 'react-native-paper';
import {useAuth0} from '../providers/AuthentiationProvider';
import StandardCard from '../components/StandardCard';
import RestrictedScrollView from '../components/RestrictedScrollView';
import FrontImage from '../components/FrontImage';
import BlindCard from '../components/BlindCard';
import TemperatureCard from '../components/TemperatureCard';
import ShortHandChips from '../components/ShortHandChips';
import {StackNavigationProp} from '@react-navigation/stack';
import {HomeTabParamList} from '../types';

export default function HomeScreen({navigation}: { navigation: StackNavigationProp<HomeTabParamList, 'overview'> }) {
    const {user, decodedAccessToken} = useAuth0();
    return (
        <RestrictedScrollView>
            <StandardCard>
                <Card.Content>
                    <Title>Willkommen Zuhause, {user?.given_name || user?.name || 'Unbekannter'}.</Title>
                    <FrontImage width={125} height={74}
                        style={{marginTop: 8, marginBottom: -16, alignSelf: 'flex-end'}}/>
                </Card.Content>
            </StandardCard>
            {(decodedAccessToken?.permissions.includes('use:hand')
                || decodedAccessToken?.permissions.includes('update:door')) && <ShortHandChips/>}
            {decodedAccessToken?.permissions.includes('update:blind') && <BlindCard navigation={navigation}/>}
            {decodedAccessToken?.permissions.includes('update:temp') &&
            <TemperatureCard navigation={navigation} overviewMode={true}/>}
        </RestrictedScrollView>
    );
}
