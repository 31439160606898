import React, {useContext, useEffect, useRef, useState} from 'react';
import {useAuth0} from './AuthentiationProvider';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useGekko} from './GekkoApiProvider';

interface InternalItem<T = (string | null)> {
    identifier: string;
    name: string;
    currentValue: T;
}

export interface GekkoStateContext {
    door: InternalItem | undefined;
    blinds: Record<string, InternalItem<{ toggle: -2 | 0 | 2, height: number }>> | undefined;
    hands: Record<string, InternalItem<0 | 1>> | undefined;
    temps: Record<string, InternalItem<{ target: number, actual: number, mode: 8 | 16 | 1, offset: number }>> | undefined;
}

export const GekkoStateContext = React.createContext<GekkoStateContext>({
    door: undefined,
    blinds: undefined,
    hands: undefined,
    temps: undefined
});

/**
 * ```ts
 * const {
 *   // State information:
 *   door,
 *   blinds,
 *   hands
 * } = useGekkoState();
 * ```
 *
 * Use the `useGekkoState` hook in your components to access the global state information
 */
export const useGekkoState = (): GekkoStateContext => useContext(GekkoStateContext);

/**
 * ```jsx
 * <GekkoStateProvider/>
 * ```
 *
 * Provides the GekkoStateContext to its child components. Using the `useGekkoState` hook,
 * you can access the global Gekko State
 */
export function GekkoStateProvider({children}: { children: React.ReactNode }) {
    // As the global state is refresh periodically when the App is in the foreground,
    // we use a separate Provider to minimize computational overhead
    const {accessToken} = useAuth0();
    const {triggerAction} = useGekko();
    const [door, setDoor] = useState<InternalItem | undefined>(undefined);
    const [blinds, setBlinds] = useState<GekkoStateContext['blinds'] | undefined>(undefined);
    const [hands, setHands] = useState<Record<string, InternalItem<0 | 1>> | undefined>(undefined);
    const [temps, setTemps] = useState<GekkoStateContext['temps'] | undefined>(undefined);
    const updateCycleData = useRef({accessToken, triggerAction, initialized: false, aborted: false, counter: 0});

    useEffect(() => {
        async function getCachedState() {
            const doorStored = await AsyncStorage.getItem('door');
            const blindsStored = await AsyncStorage.getItem('blinds');
            const handsStored = await AsyncStorage.getItem('hands');
            const tempsStored = await AsyncStorage.getItem('temps');

            if (doorStored && blindsStored && handsStored && tempsStored) {
                setDoor(JSON.parse(doorStored));
                setBlinds(JSON.parse(blindsStored));
                setHands(JSON.parse(handsStored));
                setTemps(JSON.parse(tempsStored));
            }
            updateCycleData.current.initialized = true;
        }

        getCachedState();
    }, []);

    useEffect(() => {
        updateCycleData.current.accessToken = accessToken;
    }, [accessToken]);

    useEffect(() => {
        updateCycleData.current.triggerAction = triggerAction;
    }, [triggerAction]);

    useEffect(() => {
        async function updateStateAsync() {
            while (!updateCycleData.current.aborted) {
                if (updateCycleData.current.initialized && updateCycleData.current.accessToken) {
                    try {
                        const {
                            door,
                            blinds,
                            hands,
                            temps
                        }: GekkoStateContext = await updateCycleData.current.triggerAction('state', 'GET') as GekkoStateContext;
                        setDoor(door);
                        setBlinds(blinds);
                        setHands(hands);
                        setTemps(temps);
                        if (updateCycleData.current.counter === 10) {
                            updateCycleData.current.counter = 0;
                            await AsyncStorage.setItem('door', JSON.stringify(door || {}));
                            await AsyncStorage.setItem('blinds', JSON.stringify(blinds || []));
                            await AsyncStorage.setItem('hands', JSON.stringify(hands || []));
                            await AsyncStorage.setItem('temps', JSON.stringify(temps || []));
                        }
                        updateCycleData.current.counter += 1;
                    } catch (e) {
                        // Unstable connections and server restarts can cause errors. As we poll periodically, we don't care about errors.
                    }
                }
                await new Promise((resolve) => setTimeout(resolve, 1000));
            }
        }

        updateStateAsync();
        return () => {
            updateCycleData.current.aborted = true;
        };
    }, []);

    return (
        <GekkoStateContext.Provider
            value={{
                door,
                blinds,
                hands,
                temps
            }}
        >
            {children}
        </GekkoStateContext.Provider>
    );
}
