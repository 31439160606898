import {DarkTheme, DefaultTheme, NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import * as React from 'react';
import NotFoundScreen from '../screens/NotFoundScreen';
import {RootStackParamList} from '../types';
import BottomTabNavigator, {BottomTabNavigatorWeb} from './BottomTabNavigator';
import LinkingConfiguration from './LinkingConfiguration';
import Login from '../screens/Login';
import {useAuth0} from '../providers/AuthentiationProvider';
import {useColorScheme} from '../providers/ColorSchemeProvider';
import {Platform} from 'react-native';

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export default function Navigation() {
    const {accessToken, initialized} = useAuth0();
    const { colorScheme } = useColorScheme();
    return (
        <NavigationContainer
            linking={accessToken || !initialized ? LinkingConfiguration.loggedIn : LinkingConfiguration.loggedOut}
            theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
            <RootNavigator/>
        </NavigationContainer>
    );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
    const {accessToken} = useAuth0();
    return (
        <Stack.Navigator screenOptions={{headerShown: false}}>
            {accessToken ? (
                <Stack.Screen
                    name="Root"
                    component={Platform.OS === 'web' ? BottomTabNavigatorWeb : BottomTabNavigator}
                    options={{animationTypeForReplace: 'push'}}
                />
            ) : (
                <Stack.Screen
                    name="Login"
                    component={Login}
                    options={{
                        title: 'Login',
                        animationTypeForReplace: 'pop'
                    }}
                />
            )}
            <Stack.Screen name="NotFound" component={NotFoundScreen} options={{title: 'Oops!'}}/>
        </Stack.Navigator>
    );
}

