import React, {useContext, useEffect, useState} from 'react';
import {useAuth0} from './AuthentiationProvider';
import Constants from 'expo-constants';
import {Platform} from 'react-native';
import * as Notifications from 'expo-notifications';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface Auth0User {
    user_id: string;
    email: string;
    name: string;
    last_login: string;
    picture: string;
    permissions: string[];
}

interface Auth0Permission {
    value: string;
    description: string;
}

export interface GekkoContext {
    users: Auth0User[] | undefined;
    fillUsers: () => Promise<void>;
    permissionDescriptions: Record<string, string> | undefined;
    fillPermissionDescriptions: () => Promise<void>;
    triggerAction: <T>(
        url: string,
        method: 'GET' | 'POST' | 'PUT' | 'DELETE',
        body?: Record<string, unknown> | unknown[] | unknown,
        ignoreErrors?: boolean
    ) => Promise<T | undefined>;
    enableNotifications: boolean;
    setEnableNotifications: (enabled: boolean) => void;
    notificationHooks: Record<string, string[]>;
    fillNotificationHooks: () => Promise<void>;
    setNotificationHooks: (triggers: Record<string, string[]>) => void;
    blindGroups: { name: string, blinds: string[] }[];
    setBlindGroups: (groups: { name: string, blinds: string[] }[]) => void;
    favoriteTemps: string[];
    setFavoriteTemps: (temps: string[]) => void;
}

interface GekkoProviderOptions {
    /**
     * The child nodes your provider has wrapped.
     */
    children: React.ReactElement;
    /**
     * Base URL to the API endpoint
     */
    apiUrl: string;
}

export const GekkoContext = React.createContext<GekkoContext>({
    triggerAction: () => Promise.reject('API Not initialized'),
    users: undefined,
    fillUsers: () => Promise.reject('API Not initialized'),
    permissionDescriptions: {},
    fillPermissionDescriptions: () => Promise.reject('API Not initialized'),
    enableNotifications: true,
    setEnableNotifications: () => undefined,
    notificationHooks: {},
    fillNotificationHooks: () => Promise.resolve(),
    setNotificationHooks: () => undefined,
    blindGroups: [],
    setBlindGroups: () => undefined,
    favoriteTemps: [],
    setFavoriteTemps: () => undefined,
});

async function registerForPushNotificationsAsync(): Promise<string | undefined> {
    let token;
    if (Constants.isDevice && Platform.OS !== 'web') {
        const {status: existingStatus} = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
            const {status} = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        if (finalStatus !== 'granted') {
            return;
        }
        token = (await Notifications.getExpoPushTokenAsync()).data;
    }

    if (Platform.OS === 'android') {
        await Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: '#FF231F7C',
        });
    }

    return token;
}

async function triggerAction<T>(
    fetchAuthorized: (url: string, requestInfo: RequestInit) => Promise<Response>,
    apiUrl: string,
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
    body?: Record<string, unknown> | unknown[] | unknown,
    ignoreErrors = false
): Promise<T | undefined> {
    const result = await fetchAuthorized(
        `${apiUrl}/${url}`,
        {
            method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: body ? JSON.stringify(body) : undefined
        }
    );
    if (result.status === 204) {
        return undefined;
    } else if (result.status < 400) {
        return result.json();
    } else if (!ignoreErrors) {
        throw new Error(`Request ${url} failed with status ${result.status}`);
    }
    return undefined;
}

async function fillUsers(
    fetchAuthorized: (url: string, requestInfo: RequestInit) => Promise<Response>,
    apiUrl: string,
    setUsers: (users: Auth0User[] | undefined) => void
): Promise<void> {
    setUsers(await triggerAction<Auth0User[]>(fetchAuthorized, apiUrl, 'users'));
}

async function fillNotificationHooks(
    fetchAuthorized: (url: string, requestInfo: RequestInit) => Promise<Response>,
    apiUrl: string,
    setNotificationHooks: (hooks: Record<string, string[]>) => void,
    userId: string | undefined
): Promise<void> {
    if (userId) {
        setNotificationHooks(
            await triggerAction<Record<string, string[]>>(
                fetchAuthorized,
                apiUrl,
                `users/${userId}/notificationHooks`,
                'GET',
                undefined,
                true
            ) as Record<string, string[]> || []
        );
    }
}

async function fillPermissionDescriptions(
    fetchAuthorized: (url: string, requestInfo: RequestInit) => Promise<Response>,
    apiUrl: string,
    setPermissionDescriptions: (permissions: Record<string, string>) => void
): Promise<void> {
    try {
        const permissions = await triggerAction<Auth0Permission[]>(
            fetchAuthorized,
            apiUrl,
            'permissions',
        );
        const permissionRecords: Record<string, string> = {};
        permissions?.forEach((permission) => {
            permissionRecords[permission.value] = permission.description;
        });
        setPermissionDescriptions(permissionRecords);
    } catch (e) {
        // Ignore
    }
}

async function fillBlindGroups(
    fetchAuthorized: (url: string, requestInfo: RequestInit) => Promise<Response>,
    apiUrl: string,
    setBlindGroups: (blindGroups: { name: string, blinds: string[] }[]) => void,
    userId: string | undefined
): Promise<void> {
    if (userId) {
        try {
            setBlindGroups(
                await triggerAction<{ name: string, blinds: string[] }[]>(
                    fetchAuthorized,
                    apiUrl,
                    `users/${userId}/blindGroups`
                ) as { name: string, blinds: string[] }[]
            );
        } catch (e) {
            // Ignore
        }
    }
}

async function fillFavoriteTemps(
    fetchAuthorized: (url: string, requestInfo: RequestInit) => Promise<Response>,
    apiUrl: string,
    setFavoriteTemps: (favorites: string[]) => void,
    userId: string | undefined
): Promise<void> {
    if (userId) {
        try {
            setFavoriteTemps(
                await triggerAction<string[]>(
                    fetchAuthorized,
                    apiUrl,
                    `users/${userId}/favoriteTemps`
                ) as string[]
            );
        } catch (e) {
            // Ignore
        }
    }
}

async function updateBlindGroups(
    fetchAuthorized: (url: string, requestInfo: RequestInit) => Promise<Response>,
    apiUrl: string,
    setBlindGroups: (blindGroups: { name: string, blinds: string[] }[]) => void,
    userId: string | undefined,
    blindGroups: { name: string, blinds: string[] }[]
): Promise<void> {
    setBlindGroups(blindGroups);
    if (userId) {
        await triggerAction<{ name: string, blinds: string[] }[]>(
            fetchAuthorized,
            apiUrl,
            `users/${userId}/blindGroups`,
            'POST',
            blindGroups
        );
    }
}

async function updateFavoriteTemps(
    fetchAuthorized: (url: string, requestInfo: RequestInit) => Promise<Response>,
    apiUrl: string,
    setFavoriteTemps: (favorites: string[]) => void,
    userId: string | undefined,
    favoriteTemps: string[]
): Promise<void> {
    setFavoriteTemps(favoriteTemps);
    if (userId) {
        await triggerAction<string[]>(
            fetchAuthorized,
            apiUrl,
            `users/${userId}/favoriteTemps`,
            'POST',
            favoriteTemps
        );
    }
}

/**
 * ```ts
 * const {
 *   // State information:
 *   globalState,
 *   // Trigger action:
 *   triggerActions,
 *   // User information and retrieval trigger
 *   users,
 *   fillUsers,
 *   // Notification hooks, retrieval trigger and setter
 *   notificationTriggers,
 *   fillNotificationTriggers,
 *   setNotificationTriggers,
 *   // Permission information and retrieval trigger
 *   permissionDescriptions,
 *   fillPermissionDescriptions,
 *
 * } = useGekko();
 * ```
 *
 * Use the `useGekko` hook in your components to access the Gekko api
 */
export const useGekko = (): GekkoContext => useContext(GekkoContext);

/**
 * ```jsx
 * <GekkoProvider
 *   apiUrl={domain}>
 *   <MyApp />
 * </GekkoProvider>
 * ```
 *
 * Provides the GekkoContext to its child components. Using the `useGekko` hook,
 * you can access the state and API methods to trigger smart home actions.
 */
export function GekkoProvider({
    children,
    apiUrl
}: GekkoProviderOptions) {
    const {accessToken, decodedAccessToken, fetchAuthorized, loginState} = useAuth0();
    const [users, setUsers] = useState<Auth0User[] | undefined>(undefined);
    const [permissionDescriptions, setPermissionDescriptions] = useState<Record<string, string> | undefined>(undefined);
    const [enableNotifications, setEnableNotifications] = useState<boolean>(true);
    const [expoPushToken, setExpoPushToken] = useState<string | undefined>('');
    const [notificationHooks, setNotificationHooks] = useState<Record<string, string[]>>({});
    const [blindGroups, setBlindGroups] = useState<{ name: string, blinds: string[] }[]>([]);
    const [favoriteTemps, setFavoriteTemps] = useState<string[]>([]);

    useEffect(() => {
        async function getNotificationEnabled() {
            const enableNotificationsStored = await AsyncStorage.getItem('enableNotifications');
            switch (enableNotificationsStored) {
            case('true') : {
                setEnableNotifications(true);
                break;
            }
            case('false') : {
                setEnableNotifications(false);
                break;
            }
            }
        }

        getNotificationEnabled();
    }, []);

    useEffect(() => {
        if (decodedAccessToken?.sub) {
            fillNotificationHooks(fetchAuthorized, apiUrl, setNotificationHooks, decodedAccessToken.sub);
        }
    }, [decodedAccessToken]);

    useEffect(() => {
        async function getNotificationHooks() {
            const storedNotificationHooks = await AsyncStorage.getItem('notificationHooks');
            if (notificationHooks) {
                setNotificationHooks(JSON.parse(storedNotificationHooks || '{}'));
            }
        }

        getNotificationHooks();
    }, []);

    useEffect(() => {
        async function getBlindGroups() {
            const storedBlindGroups = await AsyncStorage.getItem('blindGroups');
            if (storedBlindGroups) {
                setBlindGroups(JSON.parse(storedBlindGroups || '[]'));
            }
        }

        getBlindGroups();
    }, []);

    useEffect(() => {
        async function getFavoriteTemps() {
            const storedFavoriteTemps = await AsyncStorage.getItem('favoriteTemps');
            if (storedFavoriteTemps) {
                setFavoriteTemps(JSON.parse(storedFavoriteTemps || '[]'));
            }
        }

        getFavoriteTemps();
    }, []);

    useEffect(() => {
        if (decodedAccessToken?.sub) {
            fillBlindGroups(fetchAuthorized, apiUrl, setBlindGroups, decodedAccessToken?.sub);
        }
    }, [decodedAccessToken]);

    useEffect(() => {
        if (accessToken && decodedAccessToken?.sub) {
            fillFavoriteTemps(fetchAuthorized, apiUrl, setFavoriteTemps, decodedAccessToken.sub);
        }
    }, [decodedAccessToken]);

    useEffect(() => {
        AsyncStorage.setItem('blindGroups', JSON.stringify(blindGroups));
    }, [blindGroups]);

    useEffect(() => {
        AsyncStorage.setItem('favoriteTemps', JSON.stringify(favoriteTemps));
    }, [favoriteTemps]);

    useEffect(() => {
        AsyncStorage.setItem('enableNotifications', `${enableNotifications}`);
    }, [enableNotifications]);

    useEffect(() => {
        AsyncStorage.setItem('enableNotifications', `${enableNotifications}`);
    }, [enableNotifications]);

    useEffect(() => {
        if (Object.keys(notificationHooks).length > 0) {
            AsyncStorage.setItem('notificationHooks', JSON.stringify(notificationHooks));
        }
    }, [notificationHooks]);

    useEffect(() => {
        registerForPushNotificationsAsync().then(token => setExpoPushToken(token));
    }, []);

    useEffect(() => {
        if (expoPushToken && decodedAccessToken?.sub) {
            let action: 'add' | 'delete' | undefined;
            if (loginState === 'sessionRestored' || loginState === 'firstLogin') {
                action = enableNotifications ? 'add' : 'delete';
            } else {
                action = 'delete';
            }
            switch (action) {
            case 'add': {
                triggerAction(
                    fetchAuthorized,
                    apiUrl,
                    `users/${encodeURIComponent(decodedAccessToken.sub)}/notificationToken`,
                    'POST',
                    {token: expoPushToken}
                );
                break;
            }
            case 'delete': {
                triggerAction(
                    fetchAuthorized,
                    apiUrl,
                    `users/${encodeURIComponent(decodedAccessToken?.sub || '')}/notificationToken`,
                    'DELETE',
                    {token: expoPushToken}
                );
                break;
            }
            }
        }
    }, [loginState, expoPushToken, enableNotifications]);

    return (
        <GekkoContext.Provider
            value={{
                triggerAction: (
                    url: string,
                    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
                    body?: Record<string, unknown> | unknown[] | unknown,
                    ignoreErrors?: boolean
                ) => triggerAction(fetchAuthorized, apiUrl, url, method, body, ignoreErrors),
                users,
                fillUsers: () => fillUsers(fetchAuthorized, apiUrl, setUsers),
                permissionDescriptions,
                fillPermissionDescriptions: () => fillPermissionDescriptions(fetchAuthorized, apiUrl, setPermissionDescriptions),
                enableNotifications,
                setEnableNotifications,
                notificationHooks,
                fillNotificationHooks: () => fillNotificationHooks(fetchAuthorized, apiUrl, setNotificationHooks, decodedAccessToken?.sub),
                setNotificationHooks,
                blindGroups,
                setBlindGroups: (blindGroups) =>
                    updateBlindGroups(fetchAuthorized, apiUrl, setBlindGroups, decodedAccessToken?.sub, blindGroups),
                favoriteTemps,
                setFavoriteTemps: (favoriteTemps) =>
                    updateFavoriteTemps(fetchAuthorized, apiUrl, setFavoriteTemps, decodedAccessToken?.sub, favoriteTemps)
            }}
        >
            {children}
        </GekkoContext.Provider>
    );
}
