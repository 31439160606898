import * as React from 'react';
import {Avatar, List, Title} from 'react-native-paper';
import StandardCard from './StandardCard';
import {useGekko} from '../providers/GekkoApiProvider';
import {StackNavigationProp} from '@react-navigation/stack';
import {AccessTabParamList} from '../types';
import ContentLoader, {Circle, Rect} from 'react-content-loader/native';
import CardListContent from './CardListContent';
import {useColorScheme} from '../providers/ColorSchemeProvider';

export default function UserOverviewCard({navigation}: { navigation: StackNavigationProp<AccessTabParamList, 'overview'> }) {
    const {users} = useGekko();
    const {colorScheme} = useColorScheme();

    return (
        <>
            <Title>Nutzerrechte verwalten</Title>
            <StandardCard>
                <CardListContent>
                    {users?.map((user) => (
                        <List.Item
                            key={user.user_id}
                            title={user.name}
                            description={user.user_id}
                            style={{paddingHorizontal: 16}}
                            descriptionNumberOfLines={1}
                            left={({style}) =>
                                <Avatar.Image
                                    style={[style, {alignSelf: 'center'}]}
                                    size={32}
                                    source={{uri: user?.picture}}
                                />
                            }
                            onPress={() => navigation.navigate('detail', {userId: user.user_id})}
                        />
                    ))}
                    {!users && (
                        <ContentLoader
                            speed={1}
                            width={270}
                            height={423}
                            viewBox="0 0 270 423"
                            backgroundColor={colorScheme === 'dark' ? '#383838' : '#eeeeee'}
                            foregroundColor={colorScheme === 'dark' ? '#444444' : '#dddddd'}
                        >
                            <Circle cx="30" cy="35" r="16"/>
                            <Rect x="70" y="19" rx="5" ry="5" width="150" height="12"/>
                            <Rect x="70" y="42" rx="5" ry="5" width="200" height="10"/>
                            <Circle cx="30" cy="106" r="16"/>
                            <Rect x="70" y="90" rx="5" ry="5" width="150" height="12"/>
                            <Rect x="70" y="113" rx="5" ry="5" width="200" height="10"/>
                            <Circle cx="30" cy="177" r="16"/>
                            <Rect x="70" y="161" rx="5" ry="5" width="150" height="12"/>
                            <Rect x="70" y="184" rx="5" ry="5" width="200" height="10"/>
                            <Circle cx="30" cy="248" r="16"/>
                            <Rect x="70" y="232" rx="5" ry="5" width="150" height="12"/>
                            <Rect x="70" y="255" rx="5" ry="5" width="200" height="10"/>
                            <Circle cx="30" cy="319" r="16"/>
                            <Rect x="70" y="303" rx="5" ry="5" width="150" height="12"/>
                            <Rect x="70" y="326" rx="5" ry="5" width="200" height="10"/>
                            <Circle cx="30" cy="390" r="16"/>
                            <Rect x="70" y="374" rx="5" ry="5" width="150" height="12"/>
                            <Rect x="70" y="397" rx="5" ry="5" width="200" height="10"/>
                        </ContentLoader>
                    )}
                </CardListContent>
            </StandardCard>
        </>
    );
}
