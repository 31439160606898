import {MutableRefObject} from 'react';

export type ResultState = 'success' | 'error' | 'pending' | 'untouched';

function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function trackPromise<T>(
    promise: Promise<T> | undefined | void,
    actionPromise: MutableRefObject<Promise<T> | undefined | void>,
    setActionState: (state: ResultState) => void
): Promise<void> {
    try {
        actionPromise.current = promise;
        setActionState('pending');
        await promise;
        if (actionPromise.current === promise) {
            setActionState('success');
            await sleep(3000);
            if (actionPromise.current === promise) {
                setActionState('untouched');
            }
        }
    } catch (e) {
        if (actionPromise.current === promise) {
            setActionState('error');
            await sleep(5000);
            if (actionPromise.current === promise) {
                setActionState('untouched');
            }
        }
    }
}
