import * as Linking from 'expo-linking';

export default {
    loggedOut: {
        prefixes: [Linking.makeUrl('/')],
        config: {
            screens: {
                Login: 'login'
            }
        }
    },
    loggedIn: {
        prefixes: [Linking.makeUrl('/')],
        config: {
            screens: {
                Root: {
                    screens: {
                        home: {
                            path: 'home',
                            initialRouteName: 'overview',
                            screens: {
                                overview: {
                                    path: 'overview'
                                },
                                blinds: {
                                    path: 'blinds'
                                },
                                temps: {
                                    path: 'temps'
                                }
                            }
                        },
                        access: {
                            path: 'access',
                            initialRouteName: 'overview',
                            screens: {
                                overview: {
                                    path: 'overview'
                                },
                                detail: {
                                    path: 'user/:userId',
                                    parse: {
                                        userId: decodeURIComponent,
                                    },
                                }
                            }
                        },
                        settings: {
                            path: 'settings',
                            screens: {
                                overview: {
                                    path: 'overview'
                                }
                            }
                        }
                    }
                },
                NotFound: '*'
            }
        }
    }
};
