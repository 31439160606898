import {Ionicons} from '@expo/vector-icons';
import {createMaterialBottomTabNavigator} from '@react-navigation/material-bottom-tabs';
import {createStackNavigator, StackHeaderProps} from '@react-navigation/stack';
import * as React from 'react';
import {Appbar, useTheme} from 'react-native-paper';
import SettingsScreen from '../screens/SettingsScreen';
import AccessScreen from '../screens/AccessScreen';
import {AccessTabParamList, BottomTabParamList, HomeTabParamList, SettingsTabParamList} from '../types';
import HomeScreen from '../screens/HomeScreen';
import Logo from '../components/Logo';
import UserDetailScreen from '../screens/UserDetailScreen';
import BlindsScreen from '../screens/BlindsScreen';
import TempsScreen from '../screens/TempsScreen';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';

const BottomTab = createMaterialBottomTabNavigator<BottomTabParamList>();
const BottomTabWeb = createBottomTabNavigator<BottomTabParamList>();

function CustomHomeTabBarIcon({focused, color}: {focused: boolean, color: string}) {
    return (<TabBarIcon name={'home' + (focused ? '' : '-outline')} color={color}/>);
}

function CustomAccessTabBarIcon({focused, color}: {focused: boolean, color: string}) {
    return (<TabBarIcon name={'key' + (focused ? '' : '-outline')} color={color}/>);
}

function CustomSettingsTabBarIcon({focused, color}: {focused: boolean, color: string}) {
    return (<TabBarIcon name={'settings' + (focused ? '' : '-outline')} color={color}/>);
}

export default function BottomTabNavigator() {
    const {colors} = useTheme();

    return (
        <BottomTab.Navigator
            shifting={true}
            activeColor={colors.primary}
            barStyle={{
                backgroundColor: colors.surface,
                elevation: 12
            }}
            sceneAnimationEnabled={true}
        >
            <BottomTab.Screen
                name="home"
                component={HomeNavigator}
                options={{
                    title: 'Zuhause',
                    tabBarIcon: CustomHomeTabBarIcon
                }}
            />
            <BottomTab.Screen
                name="access"
                component={AccessNavigator}
                options={{
                    title: 'Zugang',
                    tabBarIcon: CustomAccessTabBarIcon
                }}
            />
            <BottomTab.Screen
                name="settings"
                component={SettingsNavigator}
                options={{
                    title: 'Einstellungen',
                    tabBarIcon: CustomSettingsTabBarIcon
                }}
            />
        </BottomTab.Navigator>
    );
}

export function BottomTabNavigatorWeb() {
    const {colors} = useTheme();

    return (
        <BottomTabWeb.Navigator
            tabBarOptions={{
                style: {
                    backgroundColor: colors.surface
                }
            }}
        >
            <BottomTabWeb.Screen
                name="home"
                component={HomeNavigator}
                options={{
                    title: 'Zuhause',
                    tabBarIcon: CustomHomeTabBarIcon
                }}
            />
            <BottomTabWeb.Screen
                name="access"
                component={AccessNavigator}
                options={{
                    title: 'Zugang',
                    tabBarIcon: CustomAccessTabBarIcon
                }}
            />
            <BottomTabWeb.Screen
                name="settings"
                component={SettingsNavigator}
                options={{
                    title: 'Einstellungen',
                    tabBarIcon: CustomSettingsTabBarIcon
                }}
            />
        </BottomTabWeb.Navigator>
    );
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: { name: string; color: string }) {
    return <Ionicons size={24} {...props as never} />;
}

const Header = ({scene, previous, navigation}: StackHeaderProps) => {
    const {options} = scene.descriptor;
    const title =
        options.headerTitle !== undefined
            ? options.headerTitle
            : options.title !== undefined
                ? options.title
                : scene.route.name;
    const {colors} = useTheme();

    return (
        <Appbar.Header
            theme={{
                colors: {
                    primary: colors.surface
                }
            }}
            style={{elevation: 2}}
        >
            {previous ? (
                <Appbar.BackAction
                    accessibilityComponentType="button"
                    accessibilityTraits={['button']}
                    onPress={() => navigation.pop()}
                    color={colors.primary}
                />
            ) : (
                <Logo size={48} style={{marginLeft: 8}}/>
            )}
            <Appbar.Content
                title={title}
                titleStyle={{fontFamily: 'jost'}}
            />
        </Appbar.Header>
    );
};

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const HomeStack = createStackNavigator<HomeTabParamList>();

function HomeNavigator() {
    return (
        <HomeStack.Navigator
            screenOptions={{
                header: Header,
                animationEnabled: true
            }}
        >
            <HomeStack.Screen
                name="overview"
                component={HomeScreen}
                options={{headerTitle: 'Mein Zuhause', title: 'Mein Zuhause'}}
            />
            <HomeStack.Screen
                name="blinds"
                component={BlindsScreen}
                options={{headerTitle: 'Rollläden', title: 'Rollläden'}}
            />
            <HomeStack.Screen
                name="temps"
                component={TempsScreen}
                options={{headerTitle: 'Raumtemperaturen', title: 'Raumtemperaturen'}}
            />
        </HomeStack.Navigator>
    );
}

const AccessStack = createStackNavigator<AccessTabParamList>();

function AccessNavigator() {
    return (
        <AccessStack.Navigator
            screenOptions={{
                header: Header,
                animationEnabled: true
            }}
        >
            <AccessStack.Screen
                name="overview"
                component={AccessScreen}
                options={{headerTitle: 'Zugang', title: 'Zugang'}}
            />
            <AccessStack.Screen
                name="detail"
                key="user/:userId"
                component={UserDetailScreen}
                options={{headerTitle: 'Zugang bearbeiten', title: 'Zugang bearbeiten'}}
            />
        </AccessStack.Navigator>
    );
}

const SettingsStack = createStackNavigator<SettingsTabParamList>();

function SettingsNavigator() {
    return (
        <SettingsStack.Navigator
            screenOptions={{
                header: Header,
                animationEnabled: true
            }}
        >
            <SettingsStack.Screen
                name="overview"
                component={SettingsScreen}
                options={{headerTitle: 'Einstellungen', title: 'Einstellungen'}}
            />
        </SettingsStack.Navigator>
    );
}
