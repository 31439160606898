import * as React from 'react';
import {StyleSheet} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';

export default function RestrictedScrollView({children}: { children: React.ReactNode }) {
    return (
        <ScrollView contentContainerStyle={styles.innerView}>
            {children}
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    innerView: {
        alignSelf: 'center',
        width: '100%',
        maxWidth: 600,
        paddingVertical: 24,
        paddingHorizontal: 16,
        paddingBottom: 0
    }
});
