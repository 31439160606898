import * as React from 'react';
import {List, Text, Title} from 'react-native-paper';
import StandardCard from './StandardCard';
import {useAuth0} from '../providers/AuthentiationProvider';
import {useGekko} from '../providers/GekkoApiProvider';
import {StyleSheet} from 'react-native';
import CardListContent from './CardListContent';
import {useColorScheme} from '../providers/ColorSchemeProvider';

export default function PermissionCard() {
    const {decodedAccessToken} = useAuth0();
    const {permissionDescriptions} = useGekko();
    const {colorScheme} = useColorScheme();
    const loadingStyle = colorScheme === 'dark' ? styles.itemCardLoadingDark : styles.itemCardLoading;

    return (
        <>
            <Title>Deine Berechtigungen</Title>
            <StandardCard>
                <CardListContent>
                    {decodedAccessToken?.permissions.map((permission) => (
                        <List.Item
                            key={permission}
                            title={permissionDescriptions?.[permission] || '\u00A0'}
                            titleNumberOfLines={1}
                            description={permission}
                            titleStyle={permissionDescriptions ? {} : loadingStyle}
                        />
                    ))}
                    {!decodedAccessToken?.permissions?.length &&
                    <Text style={styles.text}>Keine Berechtigungen vorhanden.</Text>}
                </CardListContent>
            </StandardCard>
        </>
    );
}

const styles = StyleSheet.create({
    itemCardLoadingDark: {
        backgroundColor: 'rgba(255, 255, 255, .05)',
        minHeight: 20
    },
    itemCardLoading: {
        backgroundColor: 'rgba(0, 0, 0, .05)',
        minHeight: 20
    },
    text: {
        margin: 16
    }
});
