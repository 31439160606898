import * as React from 'react';
import {ColorSchemeName, StyleSheet, View} from 'react-native';
import {Card, Checkbox, DataTable, IconButton, List, Menu, Title, useTheme} from 'react-native-paper';
import {useAuth0} from '../providers/AuthentiationProvider';
import StandardButton from '../components/StandardButton';
import StandardCard from '../components/StandardCard';
import {AvatarTitle} from '../components/AvatarTitle';
import RestrictedScrollView from '../components/RestrictedScrollView';
import CardListContent from '../components/CardListContent';
import {useColorScheme} from '../providers/ColorSchemeProvider';
import {useGekko} from '../providers/GekkoApiProvider';

const colorSchemeNames = {
    light: 'Hell',
    dark: 'Dunkel',
    auto: 'System'
};

export default function SettingsScreen() {
    const {logout, refreshToken, user} = useAuth0();
    const {enableNotifications, setEnableNotifications} = useGekko();
    const {colors} = useTheme();
    const {colorSchemeNoFallback, colorScheme, setColorScheme} = useColorScheme();
    const colorSchemeDescription = colorSchemeNoFallback
        ? colorSchemeNames[colorSchemeNoFallback]
        : `System (${colorSchemeNames[colorScheme]})`;
    const [visible, setVisible] = React.useState(false);
    const openMenu = () => setVisible(true);
    const closeMenu = () => setVisible(false);
    return (
        <RestrictedScrollView>
            <Title>Nutzerkonto</Title>
            <StandardCard>
                <Card.Content>
                    <View style={styles.avatarOuter}>
                        <AvatarTitle selectedUser={user}/>
                        <IconButton
                            onPress={refreshToken}
                            icon="refresh"
                            color={colors.primary}
                            accessibilityLabel="Daten aktualisieren"
                        />
                    </View>
                </Card.Content>
                <CardListContent>
                    <DataTable>
                        <DataTable.Row>
                            <DataTable.Cell style={styles.keyColumn}>User ID</DataTable.Cell>
                            <DataTable.Cell>{user?.sub}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell style={styles.keyColumn}>Email</DataTable.Cell>
                            <DataTable.Cell>{user?.email}</DataTable.Cell>
                        </DataTable.Row>
                    </DataTable>
                </CardListContent>
                <Card.Actions>
                    <StandardButton onPress={logout}>Abmelden</StandardButton>
                </Card.Actions>
            </StandardCard>
            <Title>Einstellungen</Title>
            <StandardCard>
                <CardListContent>
                    <Menu
                        visible={visible}
                        onDismiss={closeMenu}
                        anchor={
                            <List.Item
                                title="Farbschema"
                                description={colorSchemeDescription}
                                onPress={openMenu}
                            />
                        }>
                        {
                            (['light', 'dark', null] as ColorSchemeName[]).map((scheme) => (
                                <Menu.Item
                                    key={scheme || 'auto'}
                                    title={colorSchemeNames[(scheme || 'auto')]}
                                    onPress={() => {
                                        setColorScheme(scheme);
                                        closeMenu();
                                    }}
                                />
                            ))
                        }
                    </Menu>
                    <Checkbox.Item
                        label="Benachrichtigungen auf diesem Gerät"
                        status={enableNotifications ? 'checked' : 'unchecked'}
                        onPress={() => setEnableNotifications(!enableNotifications)}
                    />
                </CardListContent>
            </StandardCard>
        </RestrictedScrollView>
    );
}

const styles = StyleSheet.create({
    avatarView: {
        flexDirection: 'row',
        alignItems: 'center',
        flexShrink: 1
    },
    avatarOuter: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 8,
        height: 36
    },
    keyColumn: {
        flex: .3
    }
});
