import * as React from 'react';
import {useEffect, useState} from 'react';
import {Chip, Dialog, List, Menu, Portal, Text, useTheme} from 'react-native-paper';
import {StyleSheet, useWindowDimensions, View} from 'react-native';
import {GekkoStateContext} from '../providers/GekkoStateProvider';
import StandardButton from './StandardButton';
import Slider from '@react-native-community/slider';
import ActionButton from './ActionButton';
import {useGekko} from '../providers/GekkoApiProvider';

const modeLabels = {
    1: 'Ausgeschaltet • 5.0°C',
    8: 'Komfort • Variabel',
    16: 'Absenkung • 15.0°C'
};

export default function TemperatureEditDialog({temp}: { temp: Exclude<GekkoStateContext['temps'], undefined>[string] | undefined }) {
    const {triggerAction} = useGekko();
    const {colors} = useTheme();
    const {width} = useWindowDimensions();
    const [mode, setMode] = useState<1 | 8 | 16>(8);
    const [defaultOffset, setDefaultOffset] = useState<number | undefined>(undefined);
    const [offset, setOffset] = useState<number | undefined>(undefined);
    const [showSwitchModeMenu, setShowSwitchModeMenu] = useState<boolean>(false);
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);

    useEffect(() => {
        if (temp) {
            changeOffset(temp.currentValue.offset);
            setMode(temp.currentValue.mode);
            setShowEditDialog(true);
        }
    }, [temp]);

    function changeOffset(value: number) {
        setOffset(value);
        setDefaultOffset(value);
    }

    function changeMode(value: 1 | 8 | 16) {
        setMode(value);
        setShowSwitchModeMenu(false);
    }

    async function saveValues() {
        await triggerAction(
            `temps/${temp?.identifier || ''}`,
            'POST',
            {currentValue: {offset, mode}}
        );
        setShowEditDialog(false);
    }

    return (
        <Portal>
            <Dialog
                visible={showEditDialog}
                onDismiss={() => setShowEditDialog(false)}
                style={[width < 432 ? styles.dialogSmall : styles.dialogWide]}
            >
                <Dialog.Title>{temp?.name}</Dialog.Title>
                <Dialog.Content>
                    <Menu
                        visible={showSwitchModeMenu}
                        onDismiss={() => setShowSwitchModeMenu(false)}
                        anchor={
                            <List.Item
                                style={{marginHorizontal: -16}}
                                title="Modus"
                                description={modeLabels[mode]}
                                onPress={() => setShowSwitchModeMenu(true)}
                            />
                        }
                    >
                        <Menu.Item onPress={() => changeMode(1)} title={modeLabels[1]}/>
                        <Menu.Item onPress={() => changeMode(16)} title={modeLabels[16]}/>
                        <Menu.Item onPress={() => changeMode(8)} title={modeLabels[8]}/>
                    </Menu>
                    {mode === 8 && (<View>
                        <Text style={styles.temperatureValue}>
                            {offset === 0 && '±'}{offset && offset > 0 ? '+' : ''}{offset?.toFixed(1)}°C
                        </Text>
                        <Slider
                            removeClippedSubviews={true}
                            minimumValue={-5}
                            maximumValue={5}
                            step={0.1}
                            value={defaultOffset}
                            onValueChange={setOffset}
                            thumbTintColor={colors.accent}
                            minimumTrackTintColor={colors.accent}
                            maximumTrackTintColor={colors.background}
                        />
                        <View style={styles.chips}>
                            <Chip mode="outlined" style={styles.chip} onPress={() => changeOffset(-3)}>-3°C</Chip>
                            <Chip mode="outlined" style={styles.chip} onPress={() => changeOffset(-1.5)}>-1.5°C</Chip>
                            <Chip mode="outlined" style={styles.chip} onPress={() => changeOffset(0)}>0°C</Chip>
                            <Chip mode="outlined" style={styles.chip} onPress={() => changeOffset(1.5)}>+1.5°C</Chip>
                            <Chip mode="outlined" style={styles.chip} onPress={() => changeOffset(3)}>+3°C</Chip>
                        </View>
                    </View>)}
                </Dialog.Content>
                <Dialog.Actions>
                    <StandardButton onPress={() => setShowEditDialog(false)}>Abbrechen</StandardButton>
                    <ActionButton onPress={saveValues} contained={true}>Speichern</ActionButton>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    );
}

const styles = StyleSheet.create({
    dialogSmall: {
        margin: 16,
        alignSelf: 'stretch'
    },
    dialogWide: {
        width: 400,
        alignSelf: 'center'
    },
    temperatureValue: {
        textAlign: 'center',
        marginTop: 8,
        marginBottom: 16
    },
    chips: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 16
    },
    chip: {
        marginHorizontal: 2
    }
});
