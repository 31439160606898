import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';

export async function setItem<T>(key: string, value: T): Promise<void> {
    const saveValue: string = (typeof value === 'string' || !value) ? (value || '') as string : JSON.stringify(value);
    if (await SecureStore.isAvailableAsync()) {
        if (value) {
            return SecureStore.setItemAsync(key, saveValue);
        } else {
            return SecureStore.deleteItemAsync(key);
        }
    } else {
        if (value) {
            return AsyncStorage.setItem(key, saveValue);
        } else {
            return AsyncStorage.removeItem(key);
        }
    }
}

export async function getItem<T>(key: string): Promise<T | null> {
    let result: string | null;
    if (await SecureStore.isAvailableAsync()) {
        result = await SecureStore.getItemAsync(key);
    } else {
        result = await AsyncStorage.getItem(key);
    }
    try {
        return JSON.parse(result as string) as T;
    } catch (e) {
        return result as unknown as T;
    }
}
