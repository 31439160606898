import * as React from 'react';
import {useState} from 'react';
import {StyleSheet, useWindowDimensions} from 'react-native';
import {useGekko} from '../providers/GekkoApiProvider';
import {useGekkoState} from '../providers/GekkoStateProvider';
import {Card, Checkbox, Dialog, Divider, Portal, Text} from 'react-native-paper';
import StandardCard from '../components/StandardCard';
import CardListContent from '../components/CardListContent';
import BlindItem from '../components/BlindItem';
import StandardButton from '../components/StandardButton';
import BlindGroupSummaryItem from './BlindGroupSummaryItem';
import {InternalItem} from '../../backend/types/config';

function sortBlinds(
    a: InternalItem<{ toggle: 0 | 2 | -2, height: number }>,
    b: InternalItem<{ toggle: 0 | 2 | -2, height: number }>,
    blinds: string[]
): number {
    if (blinds.includes(a.identifier) && !blinds.includes(b.identifier)) {
        return 1;
    } else if (blinds.includes(b.identifier) && !blinds.includes(a.identifier)) {
        return -1;
    }
    return 0;
}

function deleteGroup(
    blindGroup: { name: string, blinds: string[] },
    blindGroups: { name: string, blinds: string[] }[],
    setBlindGroups: (blindGroups: { name: string, blinds: string[] }[]) => void
): void {
    setBlindGroups(blindGroups.filter((group) => group !== blindGroup));
}

function updateGroup(
    setShowEditDialog: (state: boolean) => void,
    blindGroup: { name: string, blinds: string[] },
    blindGroups: { name: string, blinds: string[] }[],
    blindCheckboxes: Record<string, boolean>,
    setBlindGroups: (blindGroups: { name: string, blinds: string[] }[]) => void
): void {
    setShowEditDialog(false);
    setBlindGroups([
        ...blindGroups.filter((group) => group !== blindGroup),
        {
            name: blindGroup.name,
            blinds: Object.entries(blindCheckboxes).filter(([, checked]) => checked).map(([key]) => key)
        }
    ]);
}

function openEditDialog(
    setShowEditDialog: (state: boolean) => void,
    setBlindCheckboxes: (state: Record<string, boolean>) => void,
    blindGroup: { name: string, blinds: string[] },
    sortedBlinds: InternalItem<{ toggle: 0 | 2 | -2, height: number }>[],
): void {
    const blindCheckboxes: Record<string, boolean> = {};
    sortedBlinds.forEach((blind) => blindCheckboxes[blind.identifier] = blindGroup.blinds.includes(blind.identifier));
    setBlindCheckboxes(blindCheckboxes);
    setShowEditDialog(true);
}

export default function BlindGroup({
    blindGroup,
    editable
}: { blindGroup: { name: string, blinds: string[] }, editable: boolean }) {
    const {blindGroups, setBlindGroups} = useGekko();
    const {blinds} = useGekkoState();
    const {width} = useWindowDimensions();
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [blindCheckboxes, setBlindCheckboxes] = useState<Record<string, boolean>>({});
    const blindList = Object.values(blinds || {})
        .filter((blind) => blindGroup.blinds.includes(blind.identifier));
    const sortedBlinds = Object.values(blinds || {})
        .sort((a, b) => sortBlinds(a, b, blindGroup.blinds));

    return (
        <>
            {(editable || blindGroup.blinds.length > 0) && <StandardCard>
                <CardListContent>
                    <BlindGroupSummaryItem boldTitle={true} blindGroup={blindGroup}/>
                </CardListContent>
                <Divider style={styles.divider}/>
                {blindList.map((blind) => (
                    <BlindItem key={blind.identifier} blind={blind}/>
                ))}
                {!blindList.length && <Text style={styles.text}>Keine Rollläden vorhanden</Text>}
                {editable && (
                    <Card.Actions>
                        <StandardButton
                            onPress={() => openEditDialog(setShowEditDialog, setBlindCheckboxes, blindGroup, sortedBlinds)}
                        >
                            Bearbeiten
                        </StandardButton>
                        <StandardButton onPress={() => setShowDeleteDialog(true)}>Löschen</StandardButton>
                    </Card.Actions>
                )}
            </StandardCard>
            }
            {editable && (
                <Portal>
                    <Dialog
                        visible={showEditDialog}
                        onDismiss={() => setShowEditDialog(false)}
                        style={[width < 432 ? styles.dialogSmall : styles.dialogWide]}
                    >
                        <Dialog.Title>&ldquo;{blindGroup.name}&ldquo; bearbeiten</Dialog.Title>
                        <Dialog.Content>
                            {sortedBlinds.map((blind) => (
                                <Checkbox.Item
                                    key={blind.identifier}
                                    label={blind.name}
                                    status={blindCheckboxes[blind.identifier] ? 'checked' : 'unchecked'}
                                    onPress={() => setBlindCheckboxes({
                                        ...blindCheckboxes,
                                        [blind.identifier]: !blindCheckboxes[blind.identifier]
                                    })}
                                />
                            ))}
                        </Dialog.Content>
                        <Dialog.Actions>
                            <StandardButton onPress={() => setShowEditDialog(false)}>Abbrechen</StandardButton>
                            <StandardButton
                                onPress={() => updateGroup(setShowEditDialog, blindGroup, blindGroups, blindCheckboxes, setBlindGroups)}
                                mode="contained"
                            >
                                Speichern
                            </StandardButton>
                        </Dialog.Actions>
                    </Dialog>
                    <Dialog
                        visible={showDeleteDialog}
                        onDismiss={() => setShowDeleteDialog(false)}
                        style={[width < 432 ? styles.dialogSmall : styles.dialogWide]}
                    >
                        <Dialog.Title>{blindGroup.name} löschen</Dialog.Title>
                        <Dialog.Content>
                            <Text>Möchtest du die Gruppe tatsächlich löschen?</Text>
                        </Dialog.Content>
                        <Dialog.Actions>
                            <StandardButton onPress={() => setShowDeleteDialog(false)}>Abbrechen</StandardButton>
                            <StandardButton
                                onPress={() => deleteGroup(blindGroup, blindGroups, setBlindGroups)}
                                mode="contained"
                            >
                                Löschen
                            </StandardButton>
                        </Dialog.Actions>
                    </Dialog>
                </Portal>
            )}
        </>
    );
}

const styles = StyleSheet.create({
    text: {
        margin: 16
    },
    divider: {
        marginHorizontal: 16
    },
    dialogSmall: {
        margin: 16,
        alignSelf: 'stretch'
    },
    dialogWide: {
        width: 400,
        alignSelf: 'center'
    }
});
