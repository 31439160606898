import * as React from 'react';
import {Avatar, Chip, Title} from 'react-native-paper';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';
import {Auth0User} from '../../backend/routes/util/user-data-cache';
import {useAuth0, User} from '../providers/AuthentiationProvider';

export function AvatarTitle({
    selectedUser,
    viewStyle,
    imageSize = 36,
    showSelfChip = false
}: {
    selectedUser: Auth0User | User | null | undefined,
    viewStyle?: StyleProp<ViewStyle>
    imageSize?: number,
    showSelfChip?: boolean
}) {
    const {user} = useAuth0();
    return (
        <View style={[viewStyle, styles.avatarView]}>
            <Avatar.Image size={imageSize} source={{uri: selectedUser?.picture}} style={styles.avatar}/>
            <Title>{selectedUser?.name || 'Unbekannter Nutzer'}</Title>
            {
                showSelfChip
                && user?.sub === ((selectedUser as User)?.sub || (selectedUser as Auth0User)?.user_id)
                && <Chip style={styles.chip}>Ich</Chip>
            }
        </View>
    );
}

const styles = StyleSheet.create({
    avatarView: {
        flexDirection: 'row',
        alignItems: 'center',
        flexShrink: 1
    },
    avatar: {
        marginRight: 16
    },
    chip: {
        marginLeft: 8
    }
});
