import * as React from 'react';
import {useState} from 'react';
import {StyleSheet} from 'react-native';
import {GekkoContext, useGekko} from '../providers/GekkoApiProvider';
import {useGekkoState} from '../providers/GekkoStateProvider';
import {IconButton, List, Menu, useTheme} from 'react-native-paper';

async function toggleBlinds(
    triggerAction: GekkoContext['triggerAction'],
    blindIdentifiers: string[],
    toggle: -2 | 0 | 2
): Promise<void> {
    await triggerAction(
        'blinds',
        'POST',
        blindIdentifiers.map((identifier) => ({identifier, currentValue: {toggle}}))
    );
}

async function setPercentage(
    triggerAction: GekkoContext['triggerAction'],
    setMenuOpen: (open: boolean) => void,
    blindIdentifiers: string[],
    percentage: number
): Promise<void> {
    setMenuOpen(false);
    await triggerAction(
        'blinds',
        'POST',
        blindIdentifiers.map((identifier) => ({identifier, currentValue: {height: percentage}}))
    );
}

function getButtonValue(
    toggleValue: -2 | 0 | 2,
    switchValue: -2 | 2
): -2 | 0 | 2 {
    return toggleValue === switchValue ? 0 : switchValue;
}

export default function BlindGroupSummaryItem(
    {blindGroup, boldTitle}: { blindGroup: { name: string, blinds: string[] }, boldTitle: boolean }
) {
    const {triggerAction} = useGekko();
    const {blinds} = useGekkoState();
    const {colors} = useTheme();
    const [menuOpen, setMenuOpen] = useState(false);
    const blindList = Object.values(blinds || {})
        .filter((blind) => blindGroup.blinds.includes(blind.identifier));

    const blindsTop = blindList.filter((blind) =>
        blind.currentValue?.height < 33).length;
    const blindsMid = blindList.filter((blind) =>
        blind.currentValue?.height > 33 && blind.currentValue?.height < 67).length;
    const blindsBottom = blindList.filter((blind) =>
        blind.currentValue?.height >= 67).length;

    // We'll consider the toggle active as soon as one blind has it active
    const toggleUpValue = blindList.some((blind) => blind.currentValue?.toggle === 2) ? 2 : 0;
    const toggleDownValue = blindList.some((blind) => blind.currentValue?.toggle === -2) ? -2 : 0;

    return (
        <List.Item
            title={blindGroup.name}
            titleStyle={boldTitle && styles.title}
            description={`${blindsTop} offen • ${blindsMid} halboffen • ${blindsBottom} geschlossen`}
            right={() => (
                <>
                    <IconButton
                        icon="arrow-up"
                        style={styles.iconButton}
                        color={toggleUpValue === 2 ? colors.accent : colors.text}
                        onPress={() => toggleBlinds(triggerAction, blindGroup.blinds, getButtonValue(toggleUpValue, 2))}
                    />
                    <IconButton
                        icon="arrow-down"
                        style={styles.iconButton}
                        color={toggleDownValue === -2 ? colors.accent : colors.text}
                        onPress={() => toggleBlinds(triggerAction, blindGroup.blinds, getButtonValue(toggleDownValue, -2))}
                    />
                    <Menu
                        visible={menuOpen}
                        onDismiss={() => setMenuOpen(false)}
                        anchor={
                            <IconButton
                                icon="percent-outline"
                                style={styles.iconButton}
                                color={colors.text}
                                onPress={() => setMenuOpen(true)}
                            />
                        }
                    >
                        <Menu.Item onPress={() => setPercentage(triggerAction, setMenuOpen, blindGroup.blinds, 25)}
                            title="25%"/>
                        <Menu.Item onPress={() => setPercentage(triggerAction, setMenuOpen, blindGroup.blinds, 50)}
                            title="50%"/>
                        <Menu.Item onPress={() => setPercentage(triggerAction, setMenuOpen, blindGroup.blinds, 70)}
                            title="75%"/>
                    </Menu>
                </>
            )}
        />
    );
}

const styles = StyleSheet.create({
    title: {
        fontFamily: 'jost-bold'
    },
    iconButton: {
        marginHorizontal: 0
    }
});
