import * as React from 'react';
import {useEffect, useState} from 'react';
import {IconButton, List} from 'react-native-paper';
import {GekkoContext, useGekko} from '../providers/GekkoApiProvider';
import {StyleSheet} from 'react-native';
import {useColorScheme} from '../providers/ColorSchemeProvider';
import {Auth0User} from '../../backend/routes/util/user-data-cache';

async function triggerDeletePermission(
    disabled: boolean,
    setDisabled: (state: boolean) => void,
    triggerAction: GekkoContext['triggerAction'],
    fillUsers: () => Promise<void>,
    userId: string | undefined,
    permission: string
): Promise<void> {
    if (userId && !disabled) {
        setDisabled(true);
        try {
            await triggerAction(
                `users/${encodeURIComponent(userId)}/permissions`,
                'DELETE',
                {permission}
            );
            await fillUsers();
        } catch (e) {
            setDisabled(false);
        }
    }
}

export default function UserPermissionItem(
    {permission, user}: { permission: string, user: Auth0User }
) {
    const [disabled, setDisabled] = useState<boolean>(false);
    const {triggerAction, fillUsers, permissionDescriptions} = useGekko();
    const {colorScheme} = useColorScheme();
    const loadingStyle = colorScheme === 'dark' ? styles.itemCardLoadingDark : styles.itemCardLoading;

    useEffect(() => {
        setDisabled(false);
    }, [user]);
    return (
        <List.Item
            title={permissionDescriptions?.[permission] || ' '}
            titleStyle={permissionDescriptions ? {} : loadingStyle}
            description={permission}
            disabled={disabled}
            descriptionNumberOfLines={1}
            right={() => <IconButton
                icon="close"
                onPress={() => triggerDeletePermission(disabled, setDisabled, triggerAction, fillUsers, user.user_id, permission)}
            />}
        />
    );
}

const styles = StyleSheet.create({
    itemCardLoadingDark: {
        backgroundColor: 'rgba(255, 255, 255, .05)',
        minHeight: 20
    },
    itemCardLoading: {
        backgroundColor: 'rgba(0, 0, 0, .05)',
        minHeight: 20
    }
});
