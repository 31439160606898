import * as React from 'react';
import {useEffect} from 'react';
import {StyleSheet} from 'react-native';
import {RouteProp, useFocusEffect} from '@react-navigation/native';
import {useGekko} from '../providers/GekkoApiProvider';
import StandardCard from '../components/StandardCard';
import {ActivityIndicator, Card, Menu, Subheading, Text, useTheme} from 'react-native-paper';
import {AccessTabParamList} from '../types';
import {StackNavigationProp} from '@react-navigation/stack';
import {AvatarTitle} from '../components/AvatarTitle';
import RestrictedScrollView from '../components/RestrictedScrollView';
import NotificationHookItem from '../components/NotificationHookItem';
import CardListContent from '../components/CardListContent';
import StandardButton from '../components/StandardButton';
import UserPermissionItem from '../components/UserPermissionItem';
import UserAddPermissionEntry from '../components/UserAddPermissionEntry';

const notificationHooks: { event: string, label: string }[] = [
    {event: 'door.open', label: 'Beim Öffnen der Haustüre'}
];

export default function UserDetailScreen(
    {
        route,
        navigation
    }: {
        route: RouteProp<AccessTabParamList, 'detail'>,
        navigation: StackNavigationProp<AccessTabParamList, 'detail'>
    }
) {
    const {users, fillUsers, permissionDescriptions, fillPermissionDescriptions} = useGekko();
    const user = users?.find?.((user) => user.user_id === route.params.userId);
    const {colors} = useTheme();

    const [visible, setVisible] = React.useState(false);
    const openMenu = () => setVisible(true);
    const closeMenu = () => setVisible(false);

    useFocusEffect(React.useCallback(() => {
        if (!route.params) {
            navigation.navigate('overview');
        }
        if (!users) {
            fillUsers();
        }
        if (!permissionDescriptions) {
            fillPermissionDescriptions();
        }
    }, [route.params]));

    useEffect(() => {
        if (!route.params && !users && !user) {
            navigation.navigate('overview');
        }
    }, [route.params, users, user]);

    if (!route.params) {
        navigation.navigate('overview');
        return <></>;
    }
    if (!route.params || !users) {
        return (
            <RestrictedScrollView>
                <ActivityIndicator size="large"/>
            </RestrictedScrollView>
        );
    }
    const availablePermissions = user
        ? Object.keys(permissionDescriptions || {})
            .filter((permission) => !user?.permissions.includes(permission))
        : [];
    return (
        <RestrictedScrollView>
            <AvatarTitle selectedUser={user} showSelfChip={true} viewStyle={styles.avatar} imageSize={64}/>
            {!user && <Text>Der gewählte Nutzer kann nicht gefunden werden.</Text>}
            {user
            && (<>
                <Subheading>Zugewiesene Berechtigungen</Subheading>
                <StandardCard>
                    <CardListContent>
                        {user.permissions.map((permission) => (
                            <UserPermissionItem permission={permission} user={user}
                                key={`${permission}-${user.user_id}`}/>
                        ))}
                        {user.permissions.length === 0 &&
                        <Text style={[styles.text, {color: colors.disabled}]}>Keine Berechtigungen vergeben.</Text>}
                    </CardListContent>
                    <Card.Actions>
                        <Menu
                            visible={visible}
                            onDismiss={closeMenu}
                            anchor={
                                <StandardButton onPress={openMenu} icon="plus">
                                    Berechtigung vergeben
                                </StandardButton>}>
                            {availablePermissions.length
                                ? availablePermissions.map((permission) => (
                                    <UserAddPermissionEntry
                                        permission={permission}
                                        user={user}
                                        key={`${permission}-${user.user_id}`}
                                        onComplete={closeMenu}
                                    />
                                ))
                                : (
                                    <Text style={[styles.text, {color: colors.disabled}]}>
                                        Keine weiteren Berechtigungen verfügbar.
                                    </Text>
                                )}
                        </Menu>
                    </Card.Actions>
                </StandardCard>
                <Subheading>Benachrichtigungen</Subheading>
                <StandardCard>
                    <CardListContent>
                        {notificationHooks.map((notificationHook) => {
                            return (
                                <NotificationHookItem
                                    key={`${notificationHook.event}_${user.user_id}`}
                                    eventId={notificationHook.event}
                                    label={notificationHook.label}
                                    subjectUserId={user.user_id}/>
                            );
                        })}
                    </CardListContent>
                </StandardCard>
            </>)
            }
        </RestrictedScrollView>
    );
}

const styles = StyleSheet.create({
    avatar: {
        marginBottom: 16
    },
    text: {
        margin: 16
    }
});
