import * as React from 'react';
import {Card} from 'react-native-paper';
import {StyleSheet} from 'react-native';

export default function StandardCard({style, ...props}: React.ComponentProps<typeof Card>) {
    return (
        <Card
            style={[styles.card, style]}
            {...props}
        />
    );
}

const styles = StyleSheet.create({
    card: {
        width: '100%',
        elevation: 0,
        marginBottom: 24
    }
});
