import * as React from 'react';
import {useRef, useState} from 'react';
import {StyleSheet, TextInput as RNTextInput, useWindowDimensions} from 'react-native';
import {Dialog, Portal, TextInput} from 'react-native-paper';
import StandardButton from './StandardButton';
import {useGekko} from '../providers/GekkoApiProvider';

export default function AddBlindGroupDialog() {
    const {blindGroups, setBlindGroups} = useGekko();
    const {width} = useWindowDimensions();
    const [text, setText] = React.useState('');
    const inputRef = useRef<RNTextInput>();
    const [showDialog, setShowDialog] = useState<boolean>(false);

    function addGroup() {
        setBlindGroups([...blindGroups, {name: text, blinds: []}]);
        setShowDialog(false);
    }

    function openDialog() {
        inputRef.current?.clear();
        setText('');
        setShowDialog(true);
    }

    return (
        <>
            <StandardButton
                mode="contained"
                icon="plus"
                style={styles.addButton}
                onPress={openDialog}
            >
                Neue Gruppe hinzufügen
            </StandardButton>
            <Portal>
                <Dialog
                    visible={showDialog}
                    onDismiss={() => setShowDialog(false)}
                    style={[width < 432 ? styles.dialogSmall : styles.dialogWide]}
                >
                    <Dialog.Title>Neue Gruppe hinzufügen</Dialog.Title>
                    <Dialog.Content>
                        <TextInput
                            ref={(input) => {
                                inputRef.current = input || undefined;
                            }}
                            label="Name"
                            mode="outlined"
                            onChangeText={text => setText(text)}
                        />
                    </Dialog.Content>
                    <Dialog.Actions>
                        <StandardButton onPress={() => setShowDialog(false)}>Abbrechen</StandardButton>
                        <StandardButton
                            onPress={addGroup}
                            mode="contained"
                            disabled={text.length === 0}
                        >
                            Speichern
                        </StandardButton>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        </>
    );
}

const styles = StyleSheet.create({
    dialogSmall: {
        margin: 16,
        alignSelf: 'stretch'
    },
    dialogWide: {
        width: 400,
        alignSelf: 'center'
    },
    temperatureValue: {
        textAlign: 'center',
        marginTop: 8,
        marginBottom: 16
    },
    chips: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 16
    },
    chip: {
        marginHorizontal: 2
    },
    addButton: {
        marginBottom: 24,
        flexGrow: 0,
        alignSelf: 'flex-start'
    }
});
