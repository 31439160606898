import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Chip, useTheme} from 'react-native-paper';
import {ResultState, trackPromise} from '../helpers/PromiseTracker';

export default function ActionChip(
    {
        onPress,
        children,
        style,
        preferPrimaryBackground,
        ...props
    }: React.ComponentProps<typeof Chip> & { preferPrimaryBackground?: boolean }
) {
    const [actionState, setActionState] = useState<ResultState>('untouched');
    const {colors} = useTheme();
    const actionPromise = useRef<Promise<unknown> | undefined | void>(undefined);
    const stateColorMapping: Record<ResultState, string | undefined> = {
        success: '#008800',
        error: colors.error,
        untouched: undefined,
        pending: undefined
    };

    const textColorMapping: Record<ResultState, string | undefined> = {
        success: '#FFFFFF',
        error: '#FFFFFF',
        untouched: undefined,
        pending: undefined
    };

    useEffect(() => {
        return () => actionPromise.current = undefined;
    }, []);

    const textFallbackColor = preferPrimaryBackground ? '#FFFFFF' : colors.onSurface;
    const backgroundFallbackColor = preferPrimaryBackground ? colors.primary : colors.surface;

    return (
        <Chip
            {...props}
            onPress={() => trackPromise(onPress?.(), actionPromise, setActionState)}
            textStyle={{color: textColorMapping[actionState] || textFallbackColor}}
            style={[style, {
                backgroundColor: stateColorMapping[actionState] || backgroundFallbackColor,
                opacity: actionState === 'pending' ? 0.5 : 1
            }]}
        >
            {children}
        </Chip>
    );
}
